import React, { useRef, useState } from 'react';
import {
  Button,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { TVisitor, TVendor, VendorSchema } from 'src/types';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { useQuery } from '@tanstack/react-query';
import * as APIt from 'src/API';
import { debug } from 'src/utils/commonUtils';
import { queryVisitorAssetTypes } from 'src/components/utils';
import { checkAssessmentPassed, checkAssessmentValidity, getLatestAssessment, getUniqUsrByPhoneNumber } from '../../utils';
import { PhoneNumberUtil } from 'google-libphonenumber';

export interface IAddVendorProps{
  addVendorCallback: Function;
  cancelAddVendorCallback: Function;
  id: number;
}

export default function AddVendor(props: IAddVendorProps) {
  debug(`AddVisitor() props is ${JSON.stringify(props)}`);

  const [errorText, setErrorText] = useState<string>();
  const [expandAssets, setExpandAssets] = useState<boolean>(false);
  const [phoneCountryCode, setPhoneCountryCode] = useState<string>('+1');
  const [vendor, setVendor] = useState<Partial<TVendor>>(
    {
      company: undefined,
      emailAddress: '',
      phoneNumber: '',
      id: props.id,
      unique_visitor_id : undefined
    });
  const [vendorAssets, setVendorAssets] = useState<APIt.VisitorAsset[]>([]);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestSTVBAccess.Vendors.AddVendor');

  const firstNameInputRef = useRef<HTMLInputElement>(null);


  interface Country {
  name: string;
  code: string;
  phoneCode: string;
}

interface PhoneCodeOption {
  label: string;
  value: string;
  country: string;
}

const getCountryPhoneCodes = (): PhoneCodeOption[] => {
  const phoneUtil = PhoneNumberUtil.getInstance()
  const regions = phoneUtil.getSupportedRegions()
  
  // Get all countries with their phone codes
  const countries = Array.from(regions).map(region => {
    const phoneCode = phoneUtil.getCountryCodeForRegion(region)
    const countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(region) || region
    return {
      label: `${countryName} (+${phoneCode})`,
      value: `+${phoneCode}`,
      country: countryName
    }
  })

  // Sort by country name
  return countries.sort((a, b) => a.country.localeCompare(b.country))
}  

  const addVendorButtonHandler = async () => {
    debug(`AddVendor() addVendorButtonHandler() vendor is ${JSON.stringify(vendor)}`);
    const parseResults = await VendorSchema.safeParseAsync(vendor);
    debug(`AddVendor() addVendorButtonHandler() parseResults is ${JSON.stringify(parseResults)}`);
    let errors = '';
    if(vendor.unique_visitor_id){
      debug(`AddVendor() addVendorButtonHandler() vendor.unique_visitor_id is ${vendor.unique_visitor_id}`);
      const latestAssessment = await getLatestAssessment(vendor.unique_visitor_id);
      debug(`AddVendor() addVendorButtonHandler() latestAssessment is ${JSON.stringify(latestAssessment)}`);
      if(latestAssessment){
        const isTestResultValid = await checkAssessmentValidity(latestAssessment);
        const hasPassedTest = await checkAssessmentPassed(latestAssessment);
        debug(`AddVendor() addVendorButtonHandler() isTestResultValid is ${isTestResultValid} and hasPassedTest is ${hasPassedTest}`);
        if(isTestResultValid && !hasPassedTest){
          errors += `${bundle.getMessage('vendor-failed-test')}; `;
        }else if(!latestAssessment.integrity_score){
          debug(`AddVendor() addVendorButtonHandler() latestAssessment.integrity_score is ${latestAssessment.integrity_score}`);
          errors += `${bundle.getMessage('vendor-pending-test-result')}; `;
        }
      }
    }
    if (!parseResults.success) {
      for (let issue of parseResults.error.issues) {
        errors += `${bundle.getMessage(issue.message)}; `;
      }
    }
    debug(`AddVendor() addVendorButtonHandler() errors is ${errors}`);
    if (errors) {
      setErrorText(errors);
    }
    if (errors.length == 0 && parseResults.success) {
      props.addVendorCallback({
        ...vendor,
        assets: vendorAssets,
      });
      setVendor(
        {
          company: undefined,
          emailAddress: '',
          phoneNumber: '',
          id: props.id,
          unique_visitor_id: undefined
        });
    }

  };

  const cancelAddVendorButtonHandler = () => {
    setVendor(
      {
        company: undefined,
        emailAddress: '',
        phoneNumber: '',
        id: props.id
      });
    props.cancelAddVendorCallback();
  };

  const visitorAssetTypesQuery = useQuery({
    queryKey: ['addVisitorAssetTypes'],
    queryFn: async () => await queryVisitorAssetTypes(),
    retry: 3
  });

  if (isBundleLoading) return <Spinner/>;

  async function loadVendorIfExists(fullPhoneNumber: string) {
    const uniqueVisitor = await getUniqUsrByPhoneNumber(fullPhoneNumber);
    if(uniqueVisitor){
      setVendor({
        ...vendor,
        emailAddress: uniqueVisitor.email_address,
        phoneNumber: uniqueVisitor.phone_number,
        firstName: uniqueVisitor.first_name,
        lastName: uniqueVisitor.last_name,
        unique_visitor_id: uniqueVisitor.id,
        company: uniqueVisitor.company_name
      });
    }
  }

  return(
    <Modal
      header={
        <Header>
          {bundle.getMessage('add-vendor')}
        </Header>
      }
      onDismiss={() => props.cancelAddVendorCallback()}
      size='large'
      visible={true}
    >
      <form onSubmit={async e => {
        e.preventDefault();
        await addVendorButtonHandler();
      }}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                data-testid='AddVendorCancelButton'
                formAction='none'
                variant='normal'
                onClick={cancelAddVendorButtonHandler}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='AddVendorAddVisitorButton'
                variant='primary'
                onClick={async () => await addVendorButtonHandler()}
              >
                {bundle.getMessage('add-vendor')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        >
          <Grid
            gridDefinition={
              [
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } }
              ]}
          > 
            <FormField label={bundle.getMessage('phone-number')}>
              <SpaceBetween direction="horizontal" size="xs">
                <Select
                  selectedOption={{ label: phoneCountryCode, value: phoneCountryCode }}
                  onChange={({ detail }) => setPhoneCountryCode(detail.selectedOption.value || '+1')}
                  options={getCountryPhoneCodes()}
                  selectedAriaLabel="Selected"
                  filteringType="auto"
                  filteringPlaceholder="Search country codes"
                  filteringAriaLabel="Filter country codes"
                />
                <Input
                  data-testid='AddVendorPhoneNumberInput'
                  onChange={({ detail }) => {
                    loadVendorIfExists(`${phoneCountryCode}${detail.value}`);
                    setVendor(
                      {
                        ...vendor,
                        phoneNumber: detail.value == '' ? undefined : `${phoneCountryCode}${detail.value}`
                      });
                  }}
                  value={vendor?.phoneNumber?.replace(phoneCountryCode, '') || ''}
                />
              </SpaceBetween>
            </FormField>
            <FormField label={bundle.getMessage('first-name')}>
              <Input
                autoFocus
                data-testid='AddVendorFirstNameInput'
                disabled={!vendor?.phoneNumber}
                onChange={({detail}) => {
                  setVendor(
                    {
                      ...vendor,
                      firstName: detail.value == '' ? undefined : detail.value
                    });
                }}
                ref={firstNameInputRef}
                value={vendor?.firstName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('last-name')}>
              <Input
                data-testid='AddVendorLastNameInput'
                disabled={!vendor?.phoneNumber}
                onChange={({detail}) => {
                  setVendor(
                    {
                      ...vendor,
                      lastName: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vendor?.lastName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('company')}>
              <Input
                data-testid='AddVendorCompanyInput'
                disabled={!vendor?.phoneNumber}
                onChange={({detail}) => {
                  setVendor(
                    {
                      ...vendor,
                      company: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vendor?.company || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('email-address')}>
              <Input
                data-testid='AddVendorEmailAddressInput'
                disabled={!vendor?.phoneNumber}
                onChange={({detail}) => {
                  setVendor(
                    {
                      ...vendor,
                      emailAddress: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vendor?.emailAddress || ''}
              />
            </FormField>
          </Grid>
          <ExpandableSection
            data-testid='AddVendorShowAssetsToggle'
            expanded={expandAssets}
            header={expandAssets ? bundle.getMessage('hide-assets') : `${bundle.getMessage('show-assets')} (${vendorAssets.length})` }
            onChange={() => setExpandAssets(!expandAssets)}
          >
            <VisitorAssetsTablePanel
             isTableLoading={false}
             readOnly={false}
             siteCode=''
             setVisitorAssets={setVendorAssets}
             username=''
             visitorAssets={vendorAssets}
             visitorAssetTypesQuery={visitorAssetTypesQuery}
             visitorId=''
           />
          </ExpandableSection>
        </Form>
      </form>
    </Modal>);
}