import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from '../../API';
import {
  getUniqueVisitorByPhoneNumber,
  getUserPrefs,
  getVisitorByPersonId,
  listLookupTypeValuesForTypeName,
  listVisitorAssetsByPSID,
  sNSPublishAccessLevelRequestApproved,
  sNSPublishAccessLevelRequestCreated,
  sNSPublishAccessLevelRequestPOCApproved,
  sNSPublishPOCApprovalRequired,
  sNSPublishVendorTestLinkGenerated,
  sNSPublishViewershipPrivilegeGranted,
  sNSPublishViewershipPrivilegeRemoved,
} from 'src/graphql/queries';
import {
  cardholder as cardholderMutation,
  createUserPrefs as createUserPrefsMutation,
  createRequest as createRequestMutation,
  createVisitor as createVisitorMutation,
  deleteRequest as deleteRequestMutation,
  updateVisitor as updateVisitorMutation,
  createUniqueVisitor,
  createVisitorAssessment,
  updateUniqueVisitor,
} from "src/graphql/mutations";
import { ApprovalStatus, LookupTypes, UnicornPACSAPIv2Priority, VisitorRequestStatus, VisitorTypes, WelcomeApplicationSettings } from 'src/constants/Constants';
import {
  auditDecorator,
  CandidateAssessmentParams,
  createCandidateAssessment,
  createEscort,
  createVisitorAccessLevel,
  createVisitorAccessLevelApproval,
  getAccessLevelApproverGroups,
  getApprovers,
  getExistingVisitorAssessments,
  getLookupTypeValueId,
  getLookupTypeValues,
  queryLookupTypeValue,
  queryLookupTypeValueForTypeAndDescription,
  queryLookupTypeValueForTypeAndValue,
  querySites,
  queryVisitorAccessLevelsByUniqueVisitor,
  sqlEscapeString
} from 'src/components/utils';
import * as uuid from 'uuid';
import { submitVisitorAccessLevelActions, updateVisitor } from '../Management/ManageAccessRequests/utils';
import { IVisitorAccessLevelWithAction } from '../Management/ManageAccessRequests/TablePanel';
import { ICreateVisitorInputWithAssets } from 'src/interfaces';
import { createVisitorAsset } from './Assets/utils';
import { AccessLevelApproverGroup } from '../../API';
import { debug } from '../../utils/commonUtils';
import { Queue } from 'queue-typescript';
import { IVendorAccessDetailsRecord } from './RequestSTVBAccess/AccessDetails/TablePanel';
import { TVendor } from 'src/types';

export let queryVisitor = async (personId: string, personSourceSystem: string): Promise<APIt.Visitor | null> => {
  debug(`queryVisitor() personId is ${personId} personSourceSystem is ${personSourceSystem}`);

  let visitor: APIt.Visitor | null = null;

  try {
    const personSourceSystemId = await getLookupTypeValueId('Person Source System', personSourceSystem);
    debug(`queryVisitor() personSourceSystemId is ${personSourceSystemId}`);

    const response = await API.graphql(graphqlOperation(getVisitorByPersonId,
    {
      person_id: personId,
      person_source_system_id: personSourceSystemId,
    }
    )) as GraphQLResult<APIt.GetVisitorByPersonIdQuery>;
    debug(`queryVisitor() response is ${JSON.stringify(response)}`);
    if (response && response.data && response.data.getVisitorByPersonId) {
      visitor = response.data.getVisitorByPersonId;
    }
  } catch(error) {
    debug(`queryVisitor() exception is ${error} JSON: ${JSON.stringify(error)}`);
    console.error(error);
    throw error;    
  }

  debug(`queryVisitor() visitor is ${JSON.stringify(visitor)}`);
  return visitor;
};
queryVisitor = auditDecorator('queryVisitor', queryVisitor);

export let createViewerOptions = (delegationType: string, data: APIt.Delegation[], username: string, employeeId: string) => {
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  let delegators = data ?? [];
  let delegatorOptions = delegators.filter(at => at.delegation_type === delegationType && (at.permanent_flag || (at.start_date && at.end_date && new Date(at.start_date) <= today && new Date(at.end_date) >= today)));
  let options = delegatorOptions.map(at => { return { label: at.delegator_username!, value: at.delegator_id }; });
  options.push({ label: username, value: employeeId.toString() });
  return options;
}

export let createVisitor = async (createVisitorInput: APIt.CreateVisitorInput): Promise<APIt.Visitor | null> => {
  debug(`createVisitor() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);

  let visitor: APIt.Visitor | null = null;

  try {
    if (createVisitorInput.first_name) createVisitorInput.first_name = sqlEscapeString(createVisitorInput.first_name);
    if (createVisitorInput.last_name) createVisitorInput.last_name = sqlEscapeString(createVisitorInput.last_name);
    if (createVisitorInput.company) createVisitorInput.company = sqlEscapeString(createVisitorInput.company);
    if (createVisitorInput.email) createVisitorInput.email = sqlEscapeString(createVisitorInput.email);
    if (createVisitorInput.phone_number) createVisitorInput.phone_number = sqlEscapeString(createVisitorInput.phone_number);
    if (createVisitorInput.person_id) createVisitorInput.person_id = sqlEscapeString(createVisitorInput.person_id);
    if (createVisitorInput.title) createVisitorInput.title = sqlEscapeString(createVisitorInput.title);
    debug(`createVisitor() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);
    const response = await API.graphql(graphqlOperation(createVisitorMutation,
      {
        input: createVisitorInput
      })) as GraphQLResult<APIt.CreateVisitorMutation>;
    if (response && response.data && response.data.createVisitor) {
      visitor = response.data.createVisitor;
    }
    if (!visitor) throw new Error('Unable to create visitor.');
  } catch(error) {
    debug(`createVisitor() exception is ${JSON.stringify(error)}`);
    console.error(error);
    throw error;
  }

  const unescortedVendorVisitorType = await queryLookupTypeValueForTypeAndValue(LookupTypes.VisitorType, VisitorTypes.UnescortedVendor);
  debug(`createVisitor() unescortedVendorVisitorType is ${JSON.stringify(unescortedVendorVisitorType)}`);

  // only continue to create a cardholder in the device management systems for unescorted vendors
  if (unescortedVendorVisitorType.id !== visitor.visitor_type_id) return visitor;

  let cardholderRequestResponse: APIt.CardholderRequestResponse | null = null;

  try {
    const cardholderInput = {
      methodName: 'AddCardholder',
      params: {
        Alias: visitor.person_id,
        Barcode: undefined,
        EmployeeStatus: 'A',
        EmployeeType: 10310,
        FirstName: visitor.first_name,
        LastName: visitor.last_name,
        Priority: UnicornPACSAPIv2Priority.HIGH,
        WelcomeID: visitor.id,
      }
    };
    debug(`createVisitor() cardholderInput is ${JSON.stringify(cardholderInput)}`);
    const cardholderMutationResponse = await API.graphql(graphqlOperation(cardholderMutation,
      {
        input: cardholderInput
      })) as GraphQLResult<APIt.CardholderMutation>;
    debug(`createVisitor() cardholderMutationResponse is ${JSON.stringify(cardholderMutationResponse)}`);
    if (cardholderMutationResponse.data && cardholderMutationResponse.data.cardholder) {
      cardholderRequestResponse = cardholderMutationResponse.data.cardholder as APIt.CardholderRequestResponse;
      if (cardholderRequestResponse.statusCode !== 202) throw new Error('Failure to create cardholder.');
      const updateVisitorInput = {
        ...createVisitorInput,
        created_by: undefined,
        requestUUID: cardholderRequestResponse.requestUUID,
        updated_by: createVisitorInput.created_by,
      };
      debug(`createVisitor() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);
      const response = await API.graphql(graphqlOperation(updateVisitorMutation,
        {
          input: updateVisitorInput
        })) as GraphQLResult<APIt.UpdateVisitorMutation>;
      if (response && response.data && response.data.updateVisitor) {
        visitor = response.data.updateVisitor;
      }
      if (!visitor) throw new Error('Unable to update visitor.');
    }
  } catch(error) {
    debug(`createVisitor() exception is ${JSON.stringify(error)}`);
    if (visitor.request_id) await deleteRequest({id: visitor.request_id, updated_by: visitor.created_by});
    console.error(error);
    throw error;
  }

  debug(`createVisitor() visitor is ${JSON.stringify(visitor)}`);
  return visitor;
};
createVisitor = auditDecorator('createVisitor', createVisitor);

export let queryDelegationTypes = async (): Promise<APIt.LookupTypeValue[]> => {
  debug('queryDelegationTypes()');  
  let delegationTypes: APIt.LookupTypeValue[] = [];
 
  try {
    const response = await API.graphql(graphqlOperation(listLookupTypeValuesForTypeName,
      {
        input: {
          limit: 1000,
          lookup_type_name: LookupTypes.DelegationType,
          offset: 0,
        }
      })) as GraphQLResult<APIt.ListLookupTypeValuesForTypeNameQuery>;
    if (response.data && response.data.listLookupTypeValuesForTypeName) {
      delegationTypes = response.data.listLookupTypeValuesForTypeName as APIt.LookupTypeValue[];
    }
  } catch (e) {
    console.error(`queryDelegationTypes() exception is ${JSON.stringify(e)}`);
    throw e;
  }
 
  return(delegationTypes);
};

export let queryVisitorAssetsByPSID = async (
  psid: string | undefined = undefined): Promise<APIt.VisitorAsset[] | null> =>
{
  debug(`queryVisitorAssetsByPSID() psid is ${psid}`);

  let employeeAssets: APIt.VisitorAsset[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAssetsByPSID,
      {
        psid: psid
      })) as GraphQLResult<APIt.ListVisitorAssetsByPSIDQuery>;
    if (response.data && response.data.listVisitorAssetsByPSID) {
      employeeAssets = response.data.listVisitorAssetsByPSID as APIt.VisitorAsset[];
    }
  } catch (e) {
    console.error(`queryVisitorAssetsByPSID() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`queryVisitorAssetsByPSID() visitorAssets is ${JSON.stringify(employeeAssets)}`);

  return(employeeAssets);
};
queryVisitorAssetsByPSID = auditDecorator('queryVisitorAssetsByPSID', queryVisitorAssetsByPSID);

export let queryUserPreferences = async (username: string): Promise<APIt.UserPrefs | null> => {
  debug(`queryUserPreferences() username is ${username}`);

  let userPrefs: APIt.UserPrefs | null = null;

  try {
    const response = await API.graphql(graphqlOperation(getUserPrefs,
      {
        username: username
      })) as GraphQLResult<APIt.GetUserPrefsQuery>;
    if (response.data && response.data.getUserPrefs) {
      userPrefs = response.data.getUserPrefs as APIt.UserPrefs;
    }
  } catch(error) {
    console.error(`queryUserPreferences() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(userPrefs);
};
queryUserPreferences = auditDecorator('queryUserPreferences', queryUserPreferences);

export let createUserPreferences = async (userPrefs: APIt.UserPrefs): Promise<APIt.UserPrefs | null> => {
  debug(`createUserPreferences() userPrefs is ${JSON.stringify(userPrefs)}`);

  let newUserPrefs: APIt.UserPrefs | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createUserPrefsMutation,
      {
        input: {...userPrefs, __typename: undefined}
      })) as GraphQLResult<APIt.CreateUserPrefsMutation>;
    if (response.data && response.data.createUserPrefs) {
      newUserPrefs = response.data.createUserPrefs as APIt.UserPrefs;
    }
  } catch(error) {
    console.error(`createUserPreferences() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(newUserPrefs);
};
createUserPreferences = auditDecorator('createUserPreferences', createUserPreferences);

export let updateUserPreferences = async (userPrefs: APIt.UserPrefs): Promise<APIt.UserPrefs | null> => {
  debug(`updateUserPreferences() userPrefs is ${JSON.stringify(userPrefs)}`);

  let updatedUserPref: APIt.UserPrefs | null = await createUserPreferences(userPrefs);

  return(updatedUserPref);
};
updateUserPreferences = auditDecorator('updateUserPreferences', updateUserPreferences);

export interface ISubmitAccessLevelRequest {
  accessLevels: APIt.CreateVisitorAccessLevelInput[];
  escorts: APIt.CreateRequestEscortInput[];
  request: APIt.CreateRequestInput;
  visitors: ICreateVisitorInputWithAssets[];
}

export let submitAccessRequest = async (accessLevelRequest: ISubmitAccessLevelRequest, disableAutoApproval: boolean = false) => {
  debug(`submitAccessRequest() accessLevelRequest is ${JSON.stringify(accessLevelRequest)} disableAutoApproval is ${disableAutoApproval}`);

  let request: APIt.Request | null = null, autoApprove = false;

  try {
    request = await createRequest(accessLevelRequest.request);
    debug(`submitAccessRequest() request is ${JSON.stringify(request)}`);
    for (let visitor of accessLevelRequest.visitors) {
      const createVisitorInput = {...visitor};
      delete createVisitorInput.assets;
      debug(`submitVisitorRequest() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);
      const createdVisitor = await createVisitor(createVisitorInput);
      debug(`submitAccessRequest() createdVisitor is ${JSON.stringify(createdVisitor)}`);
      if (visitor.assets) {
        for (let asset of visitor.assets) {
          const createdVisitorAsset = await createVisitorAsset(asset);
          debug(`submitAccessRequest() createdVisitorAsset is ${JSON.stringify(createdVisitorAsset)}`);
        };
      }
    }
    const approverSourceSystemId = await getLookupTypeValueId('Approver Source System', 'PACS');
    debug(`submitAccessRequest() approverSourceSystemId is ${approverSourceSystemId}`);
    if (!approverSourceSystemId) throw new Error(`Unable to locate approver source system id value for PACS`);
    const pendingApprovalAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status',ApprovalStatus.PendingApproval);
    debug(`submitAccessRequest() pendingApprovalAccessLevelStatusCodeId is ${pendingApprovalAccessLevelStatusCodeId}`);
    const approvedAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status',ApprovalStatus.Approved);
    debug(`submitAccessRequest() approvedAccessLevelStatusCodeId is ${approvedAccessLevelStatusCodeId}`);
    if (!pendingApprovalAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.PendingApproval}`);
    if (!approvedAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.Approved}`);
    const denyReasons = await getLookupTypeValues(LookupTypes.AccessLevelDenial);
    debug(`submitAccessRequest() denyReasons is ${JSON.stringify(denyReasons)}`);
    for (let escort of accessLevelRequest.escorts) {
      debug(`submitAccessRequest() escort is ${JSON.stringify(escort)}`);
      const createdEscort = await createEscort(escort);
      if (!createdEscort) throw new Error('Unable to create escort');
    }
    let
      createVisitorAccessLevelApprovalCalls = [],
      createVisitorAccessLevelApprovedCalls = [],
      createVisitorAccessLevelRequestApprovedCalls = [];
    const createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[] = [];
    for (let accessLevel of accessLevelRequest.accessLevels) {
      autoApprove = false;
      debug(`submitAccessRequest() accessLevel is ${JSON.stringify(accessLevel)}`);
      const visitorAccessLevel = await createVisitorAccessLevel(accessLevel);
      if (!visitorAccessLevel) {
        throw new Error('Unable to create request access level');
      }
      const approverGroups = await getAccessLevelApproverGroups(accessLevel.access_level_name);
      debug(`submitAccessRequest() approverGroups is ${JSON.stringify(approverGroups)}`);
      for (let approverGroup of approverGroups) {
        debug(`submitAccessRequest() approverGroup is ${approverGroup}`);
        let approvers = await getApprovers(approverGroup);
        debug(`submitAccessRequest() approvers is ${JSON.stringify(approvers)}`);
        if (approvers.includes(accessLevelRequest.request.requestor_id) && !disableAutoApproval) {
          autoApprove = true;
          approvers = [accessLevelRequest.request.requestor_id];
        }
        for (let approver of approvers) {
          debug(`submitAccessRequest() approver is ${approver}`);
          if (createdVisitorAccessLevelApprovals.find((a) =>
            (a.approver_id == approver
            && a.approver_source_system_id == approverSourceSystemId
            && a.visitor_access_level_id == visitorAccessLevel.id)) == undefined)
          {
            const id = uuid.v4();
            const createVisitorAccessLevelApprovalInput: APIt.CreateVisitorAccessLevelApprovalInput = {
              approver_id: approver,
              approver_source_system_id: approverSourceSystemId,
              created_by: accessLevelRequest.request.created_by,
              id: id, 
              visitor_access_level_id: visitorAccessLevel.id,
              status_code_id: autoApprove ? approvedAccessLevelStatusCodeId : pendingApprovalAccessLevelStatusCodeId,
            };
            createdVisitorAccessLevelApprovals.push(createVisitorAccessLevelApprovalInput);
            createVisitorAccessLevelApprovalCalls.push(createVisitorAccessLevelApproval(createVisitorAccessLevelApprovalInput));
          }
        }
        debug(`submitAccessRequest() autoApprove is ${autoApprove}`);
      }
      if (autoApprove) {
        const visitor = accessLevelRequest.visitors.find(v => v.id === accessLevel.visitor_id);
        debug(`submitAccessRequest() visitor is ${JSON.stringify(visitor)}`);
        let visitorType = '';
        if (visitor) visitorType = (await queryLookupTypeValue(visitor.visitor_type_id))?.value || '';
        debug(`submitAccessRequest() visitorType is ${JSON.stringify(visitorType)}`);
        const action: IVisitorAccessLevelWithAction = {
          __typename: 'VisitorAccessLevel',
          ...accessLevelRequest.request,
          ...accessLevel,
          action: { label: 'Approve', value: 'approve' },
          created: '',
          dates_updated: false,
          person_id: visitor?.person_id || '',
          request_id: accessLevelRequest.request.id,
          requestor_id: accessLevelRequest.request.requestor_id,
          requestor_source_system_id: accessLevelRequest.request.requestor_source_system_id,
          updated: '',
          updated_by: accessLevelRequest.request.requestor_id,
          visitor_type: visitorType,
        };

        try {
          createVisitorAccessLevelApprovedCalls.push(submitVisitorAccessLevelActions(
            {
              actions: [action],
              approverId: accessLevelRequest.request.requestor_id,
            }));
          createVisitorAccessLevelRequestApprovedCalls.push(() => {
            return new Promise(async (resolve, reject) => {
              try {
                const response = await API.graphql(graphqlOperation(sNSPublishAccessLevelRequestApproved,
                  {
                    requestId: request!.id
                  })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestApprovedQuery>;
                debug(`submitAccessRequest() SNSPublishAccessLevelRequestApproved response is ${JSON.stringify(response)}`);
                resolve(response);
              } catch (error) {
                reject(error);
              }
          })});
        } catch(error) {
          debug(`submitAccessRequest() SNSPublishAccessLevelRequestApproved error is ${JSON.stringify(error)}`);
          throw error;
        }
      }
    }
    await Promise.all(createVisitorAccessLevelApprovalCalls);
    await Promise.all(createVisitorAccessLevelApprovedCalls);
    await Promise.all(createVisitorAccessLevelRequestApprovedCalls);
  } catch(error) {
    if (request) await deleteRequest({id: request.id, updated_by: request.created_by});
    debug(`submitAccessRequest() error is ${error} JSON: ${JSON.stringify(error)}`);
    throw(error);
  }

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishAccessLevelRequestCreated,
      {
        requestId: request!.id
      })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestCreatedQuery>;
    if (response && response.data && response.data.SNSPublishAccessLevelRequestCreated) {
      debug(`submitAccessRequest() SNSPublishAccessLevelRequestCreated response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`submitAccessRequest() SNSPublishAccessLevelRequestCreated error is ${JSON.stringify(error)}`);
    throw error;
  }
};
function getTomorrowDate() {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toISOString().split('T')[0];
}

export const createVstrAssessment = async(visitorAssessmentInput: APIt.CreateVisitorAssessmentInput)=>{
  const response = await API.graphql(graphqlOperation(createVisitorAssessment,
    {
      input: {...visitorAssessmentInput}
    })) as GraphQLResult<APIt.CreateVisitorAssessmentMutation>;
  if (response.data && response.data.createVisitorAssessment) {
    return response.data.createVisitorAssessment as APIt.VisitorAssessment;
  }
}
export const submitVendorRequest = async (accessLevelRequest: ISubmitAccessLevelRequest, disableAutoApproval: boolean = false) => {
  debug(`submitVendorRequest() accessLevelRequest is ${JSON.stringify(accessLevelRequest)} disableAutoApproval is ${disableAutoApproval}`);
  let request: APIt.Request | null = null, autoApprove = false;

  try {

    const approverSourceSystemId = await getLookupTypeValueId('Approver Source System', 'PACS');
    debug(`submitVendorRequest() approverSourceSystemId is ${approverSourceSystemId}`);
    if (!approverSourceSystemId) throw new Error(`Unable to locate approver source system id value for PACS`);
    const pendingApprovalAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status',ApprovalStatus.PendingApproval);
    debug(`submitVendorRequest() pendingApprovalAccessLevelStatusCodeId is ${pendingApprovalAccessLevelStatusCodeId}`);
    const visitorStatusPendingAssessmentId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus,VisitorRequestStatus.PendingAssessment);
    debug(`submitVendorRequest() pendingApprovalAccessLevelStatusCodeId is ${visitorStatusPendingAssessmentId}`);
    const pendingPOCApprovalAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status',ApprovalStatus.PendingPOCApproval);
    debug(`submitVendorRequest() pendingPOCApprovalAccessLevelStatusCodeId is ${pendingPOCApprovalAccessLevelStatusCodeId}`);
    const approvedAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status',ApprovalStatus.Approved);
    debug(`submitVendorRequest() approvedAccessLevelStatusCodeId is ${approvedAccessLevelStatusCodeId}`);
    const pendingPOCApprovalVisitorRequestStatusCodeId = await getLookupTypeValueId('Visitor Request Status',VisitorRequestStatus.PendingPOCApproval);
    debug(`submitVendorRequest() approvedAccessLevelStatusCodeId is ${approvedAccessLevelStatusCodeId}`);
    if (!pendingPOCApprovalAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.PendingPOCApproval}`);
    if (!pendingApprovalAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.PendingApproval}`);
    if (!approvedAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.Approved}`);
    if (!pendingPOCApprovalVisitorRequestStatusCodeId) throw new Error(`Unable to locate status code id value for ${VisitorRequestStatus.PendingPOCApproval}`);
    const denyReasons = await getLookupTypeValues(LookupTypes.AccessLevelDenial);
    debug(`submitVendorRequest() denyReasons is ${JSON.stringify(denyReasons)}`);
    request = await createRequest(accessLevelRequest.request);
    if(!request)
      throw new Error('Unable to create request');
    debug(`submitVendorRequest() request is ${JSON.stringify(request)}`);

    let isVceEnabled = (await queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.VCEEnabled)).value.toLowerCase() === 'yes';
    debug(`submitVendorRequest() isVceEnabled is ${isVceEnabled}`);
    isVceEnabled = isVceEnabled && !(await isVCEDisabledForSelectedCountries(accessLevelRequest.accessLevels));
    debug(`submitVendorRequest() isVceEnabled with countries is ${isVceEnabled}`);
    
    for (let visitor of accessLevelRequest.visitors) {
      await ensureUniqueVisitor(visitor);
      await createVisitorAndAssets(visitor);
    
      let isTestResultValid = false;
      if(isVceEnabled){
        const latestAssessment = await getLatestAssessment(visitor.unique_visitor_id!);
        isTestResultValid = latestAssessment != null && await checkAssessmentValidity(latestAssessment) && await checkAssessmentPassed(latestAssessment);
        debug('isTestResultValid '+isTestResultValid);
      }
      if(isVceEnabled && !isTestResultValid){
        await createAndSendAssessmentLink(visitor, accessLevelRequest.accessLevels);
      } else {
        debug('vce is disabled or visitor has a valid assessment therefore current status is Pending POC Approval');
        visitor.status_id = pendingPOCApprovalVisitorRequestStatusCodeId;
        if (visitor && visitor.status_id === pendingPOCApprovalVisitorRequestStatusCodeId) {
          for (let accessLevel of accessLevelRequest.accessLevels.filter(al => al.visitor_id === visitor.id)) {
            accessLevel.status_code_id = pendingPOCApprovalAccessLevelStatusCodeId;
          }
        }
      }
    }
    const escorts = await createEscorts(accessLevelRequest);
    await processVendorRequest(accessLevelRequest, visitorStatusPendingAssessmentId, autoApprove, disableAutoApproval, approverSourceSystemId, approvedAccessLevelStatusCodeId, pendingApprovalAccessLevelStatusCodeId, pendingPOCApprovalAccessLevelStatusCodeId, request, escorts);
   
  } catch(error) {
    if (request) await deleteRequest({id: request.id, updated_by: request.created_by});
    debug(`submitVendorRequest() error is ${error} JSON: ${JSON.stringify(error)}`);
    throw(error);
  }
};

export const createRequest = async (requestInput: APIt.CreateRequestInput): Promise<APIt.Request | null> => {
  debug(`createRequest() requestInput is ${JSON.stringify(requestInput)}`);

  let createdVisit: APIt.Request | null = null;

  try {
    if (requestInput.reason) requestInput.reason = sqlEscapeString(requestInput.reason);
    debug(`createRequest() requestInput.reason is ${requestInput.reason}`);
    const response = await API.graphql(graphqlOperation(createRequestMutation,
      {
        input: requestInput
      })) as GraphQLResult<APIt.CreateRequestMutation>;
    if (response && response.data && response.data.createRequest) {
      createdVisit = response.data.createRequest;
    }
  } catch (error) {
    console.error(`createRequest(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  return createdVisit;

};

export const createUniqVisitor = async (uniqueVisitorInput: APIt.CreateUniqueVisitorInput): Promise<APIt.UniqueVisitor | null> => {
  debug(`createUniqueVisitor() requestInput is ${JSON.stringify(uniqueVisitorInput)}`);

  let createdUniqueVisitor: APIt.UniqueVisitor | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createUniqueVisitor,
      {
        input: uniqueVisitorInput
      })) as GraphQLResult<APIt.CreateUniqueVisitorMutation>;
    if (response && response.data && response.data.createUniqueVisitor) {
      createdUniqueVisitor = response.data.createUniqueVisitor;
    }
  } catch (error) {
    console.error(`createRequest(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  return createdUniqueVisitor;

};

export const updateUniqVisitor = async (uniqueVisitorInput: APIt.UpdateUniqueVisitorInput): Promise<APIt.UniqueVisitor | null> => {
  debug(`updateUniqVisitor() requestInput is ${JSON.stringify(uniqueVisitorInput)}`);

  let updatedUniqueVisitor: APIt.UniqueVisitor | null = null;

  try {
    const response = await API.graphql(graphqlOperation(updateUniqueVisitor,
      {
        input: uniqueVisitorInput
      })) as GraphQLResult<APIt.UpdateUniqueVisitorMutation>;
    if (response && response.data && response.data.updateUniqueVisitor) {
      updatedUniqueVisitor = response.data.updateUniqueVisitor;
    }
  } catch (error) {
    console.error(`updateUniqVisitor(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  return updatedUniqueVisitor;

};

export const deleteRequest = async (deleteRequestInput: APIt.DeleteRequestInput): Promise<APIt.Request | null> => {
  debug(`deleteRequest() deleteRequestInput is ${JSON.stringify(deleteRequestInput)}`);

  let deletedRequest: APIt.Request | null = null;

  try {
    const response = await API.graphql(graphqlOperation(deleteRequestMutation,
      {
        input: deleteRequestInput
      })) as GraphQLResult<APIt.DeleteRequestMutation>;
    if (response && response.data && response.data.deleteRequest) {
      deletedRequest = response.data.deleteRequest;
    }
  } catch(error) {
    console.error(`createRequest() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return deletedRequest;
};

  export const getUniqUsrByPhoneNumber = async (phoneNumber: string): Promise<APIt.UniqueVisitor | null> => {
  debug(`getUniqueUserByPhoneNumber() phoneNumber is ${phoneNumber}`);

  try {
    const response = await API.graphql(graphqlOperation(getUniqueVisitorByPhoneNumber, {
      phone_number: phoneNumber
    })) as GraphQLResult<APIt.GetUniqueVisitorByPhoneNumberQuery>;

    if (response && response.data && response.data.getUniqueVisitorByPhoneNumber) {
      const resp = response.data.getUniqueVisitorByPhoneNumber as APIt.UniqueVisitor[];
      if(resp.length > 0) return resp[0] as APIt.UniqueVisitor;
    }
    return null;
  } catch (error) {
    console.error(`getUniqueUserByPhoneNumber() error is ${JSON.stringify(error)}`);
    throw error;
  }
};  

export const accessLevelApproverGroupMembersExist = async (approverGroups: AccessLevelApproverGroup[]): Promise<boolean> => {
  let membersExist = false;
  try {
    for (let approverGroup of approverGroups) {
      let approvers = await getApprovers(approverGroup.approver_group);
      if (approvers.length > 0) {
        membersExist = true;
        break;
      }
    }
  } catch(error) {
    console.error('accessLevelApproverGroupMembers() error is ', error);
  }
  return membersExist;
};

export let viewershipAccessGrantedSendNotification = async (
delegationSNSInput: APIt.DelegationPrivilegeInput): Promise<void> =>
{
  debug(`viewershipAccessGrantedSendNotification() delegationSNSInput is ${JSON.stringify(delegationSNSInput)}`);
  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishViewershipPrivilegeGranted,
      {delegationInput: delegationSNSInput})) as GraphQLResult<APIt.SNSPublishViewershipPrivilegeGrantedQuery>;
    if (response && response.data && response.data.SNSPublishViewershipPrivilegeGranted) {
      debug(`viewershipAccessGrantedSendNotification() response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`viewershipAccessGrantedSendNotification() error is ${JSON.stringify(error)}`);
    throw error;
  }
}
viewershipAccessGrantedSendNotification = auditDecorator('viewershipAccessGrantedSendNotification', viewershipAccessGrantedSendNotification);

export let viewershipAccessRemovedSendNotication = async (
  delegationSNSInput: APIt.DelegationPrivilegeInput): Promise<void> =>
{
  debug(`viewershipAccessRemovedSendNotication() delegationSNSInput is ${JSON.stringify(delegationSNSInput)}`);
  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishViewershipPrivilegeRemoved,
     {delegationInput: delegationSNSInput})) as GraphQLResult<APIt.SNSPublishViewershipPrivilegeRemovedQuery>;
    if (response && response.data && response.data.SNSPublishViewershipPrivilegeRemoved) {
      debug(`viewershipAccessRemovedSendNotication() response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`viewershipAccessRemovedSendNotication() error is ${JSON.stringify(error)}`);
    throw error;
  }
}
viewershipAccessRemovedSendNotication = auditDecorator('viewershipAccessRemovedSendNotication', viewershipAccessRemovedSendNotication);

async function processVendorRequest(accessLevelRequest: ISubmitAccessLevelRequest, visitorStatusPendingAssessmentId: string | undefined, autoApprove: boolean, disableAutoApproval: boolean, approverSourceSystemId: string, approvedAccessLevelStatusCodeId: string, pendingApprovalAccessLevelStatusCodeId: string, pendingPOCApprovalAccessLevelStatusCodeId: string, request: APIt.Request, escorts: APIt.RequestEscort[]) {
  let
    createPOCApprovalCalls: Promise<APIt.VisitorAccessLevelApproval | null>[] = [], handlePOCApprovalCalls: Promise<void>[] = [], sendPOCApprovalRequiredNotificationCalls: Promise<void>[] = []; 
  const createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[] = [];
  for (let accessLevel of accessLevelRequest.accessLevels) {
    const visitor = accessLevelRequest.visitors.find(v => v.id === accessLevel.visitor_id);
    const isPendingAssessment = visitor?.status_id === visitorStatusPendingAssessmentId;
    await processAccessLevelRequest(accessLevel, accessLevelRequest, disableAutoApproval, createdVisitorAccessLevelApprovals, approverSourceSystemId, approvedAccessLevelStatusCodeId, pendingApprovalAccessLevelStatusCodeId, pendingPOCApprovalAccessLevelStatusCodeId, createPOCApprovalCalls, handlePOCApprovalCalls, sendPOCApprovalRequiredNotificationCalls, request, escorts, isPendingAssessment);
  }
  await Promise.all(createPOCApprovalCalls);
  await Promise.all(sendPOCApprovalRequiredNotificationCalls);
  await Promise.all(handlePOCApprovalCalls);
}

async function createEscorts(accessLevelRequest: ISubmitAccessLevelRequest): Promise<APIt.RequestEscort[]> {
  const escorts = await Promise.all(accessLevelRequest.escorts.map(async escort => {
    debug(`processVendorRequest() escort is ${JSON.stringify(escort)}`);
    const createdEscort = await createEscort(escort);
    if (!createdEscort) {
      throw new Error('Unable to create escort');
    }
    return createdEscort;
  }));
  return escorts;
}

export async function notifyPOCApprovalRequired(request: APIt.Request | null, visitorId: string) {
  debug(`notifyPOCApprovalRequired() request is ${JSON.stringify(request)}`);
  debug(`notifyPOCApprovalRequired() visitorId is ${visitorId}`);
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishPOCApprovalRequired,
      {
        requestId: request!.id, 
        visitorId: visitorId
      })) as GraphQLResult<APIt.SNSPublishPOCApprovalRequiredQuery>;
    if (response && response.data && response.data.SNSPublishPOCApprovalRequired) {
      debug(`notifyPOCApprovalRequired() SNSPublishPOCApprovalRequired response is ${JSON.stringify(response)}`);
    }
  } catch (error) {
    console.error(`notifyPOCApprovalRequired() SNSPublishPOCApprovalRequired error is ${JSON.stringify(error)}`);
    throw error;
  }
}

export async function notifyRequestAccessCreated(request: APIt.Request | null) {
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishAccessLevelRequestCreated,
      {
        requestId: request!.id
      })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestCreatedQuery>;
    if (response && response.data && response.data.SNSPublishAccessLevelRequestCreated) {
      debug(`notifyRequestAccessCreated() SNSPublishAccessLevelRequestCreated response is ${JSON.stringify(response)}`);
    }
  } catch (error) {
    console.error(`notifyRequestAccessCreated() SNSPublishAccessLevelRequestCreated error is ${JSON.stringify(error)}`);
    throw error;
  }
}

async function processAccessLevelRequest(accessLevel: APIt.CreateVisitorAccessLevelInput, accessLevelRequest: ISubmitAccessLevelRequest, disableAutoApproval: boolean, createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[], approverSourceSystemId: string, approvedAccessLevelStatusCodeId: string, pendingApprovalAccessLevelStatusCodeId: string, pendingPOCApprovalAccessLevelStatusCodeId: string, createPOCApprovalCalls: Promise<APIt.VisitorAccessLevelApproval | null>[], handlePOCApprovalCalls: Promise<void>[], sendPOCApprovalRequiredNotificationCalls: Promise<void>[], request: APIt.Request, escorts: APIt.RequestEscort[], isPendingAssessment: boolean ) {
  debug(`processAccessLevelRequest() accessLevelRequest is ${JSON.stringify(accessLevelRequest)}`);
  let pocAutoApprove = false;
  debug(`processAccessLevelRequest() accessLevel is ${JSON.stringify(accessLevel)}`);
  const visitorAccessLevel = await createVisitorAccessLevel(accessLevel);
  debug(`processAccessLevelRequest() visitorAccessLevel is ${JSON.stringify(visitorAccessLevel)}`);
  if (!visitorAccessLevel) {
    throw new Error('Unable to create request access level');
  }
   //only create approvals for visitors with a pending poc approval status
   if(isPendingAssessment)
    return;
  for(let escort of escorts) {
    if (escort.escort_id === accessLevelRequest.request.requestor_id && !disableAutoApproval) {
      pocAutoApprove = true;
    }
    await populatePOCApproverCalls(escort.escort_id, createdVisitorAccessLevelApprovals, approverSourceSystemId, visitorAccessLevel, accessLevelRequest, pocAutoApprove, pendingPOCApprovalAccessLevelStatusCodeId, pendingApprovalAccessLevelStatusCodeId, createPOCApprovalCalls);
  }
  sendPOCApprovalRequiredNotificationCalls.push(notifyPOCApprovalRequired(request, accessLevel.visitor_id));  
  if(pocAutoApprove && !disableAutoApproval){
    await populatePOCAutoApproveCalls(accessLevelRequest, accessLevel, handlePOCApprovalCalls, request);
  }
}
export async function populateAutoApproveCalls(visitor_type_id: string, person_id: string, accessLevel: APIt.CreateVisitorAccessLevelInput, createVisitorAccessLevelApprovedCalls: Promise<void>[], createVisitorAccessLevelRequestApprovedCalls: Promise<GraphQLResult<APIt.SNSPublishAccessLevelRequestApprovedQuery>>[], request: APIt.Request | null) {
 
  let visitorType = (await queryLookupTypeValue(visitor_type_id))?.value || '';
  debug(`populateAutoApproveCalls() visitorType is ${JSON.stringify(visitorType)}`);
  const requestInput: APIt.CreateRequestInput = {
    id: request!.id,
    site_id: request!.site_id,
    site_source_system_id: request!.site_source_system_id,
    reason: request!.reason,
    requestor_id: request!.requestor_id,
    requestor_source_system_id: request!.requestor_source_system_id,
    start_date: request!.start_date,
    end_date: request!.end_date,
    status: request!.status,
    created_by: request!.created_by,
  };
  const action: IVisitorAccessLevelWithAction = {
    __typename: 'VisitorAccessLevel',
    ...requestInput,
    ...accessLevel,
    action: { label: 'Approve', value: 'approve' },
    created: '',
    dates_updated: false,
    person_id: person_id || '',
    request_id: request!.id,
    requestor_id: request!.requestor_id,
    requestor_source_system_id: request!.requestor_source_system_id,
    updated: '',
    updated_by: request!.requestor_id,
    visitor_type: visitorType,
  };

  try {
    createVisitorAccessLevelApprovedCalls.push(submitVisitorAccessLevelActions(
      {
        actions: [action],
        approverId: request!.requestor_id,
      }));
    createVisitorAccessLevelRequestApprovedCalls.push(new Promise<GraphQLResult<APIt.SNSPublishAccessLevelRequestApprovedQuery>>(async (resolve, reject) => {
      try {
        const response = await API.graphql(graphqlOperation(sNSPublishAccessLevelRequestApproved,
          {
            requestId: request!.id
          })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestApprovedQuery>;
        debug(`populateAutoApproveCalls() SNSPublishAccessLevelRequestApproved response is ${JSON.stringify(response)}`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }));
  } catch (error) {
    debug(`populateAutoApproveCalls() SNSPublishAccessLevelRequestApproved error is ${JSON.stringify(error)}`);
    throw error;
  }
}

export async function populatePOCAutoApproveCalls(accessLevelRequest: ISubmitAccessLevelRequest, accessLevel: APIt.CreateVisitorAccessLevelInput, handlePOCApprovalCalls: Promise<void>[], request: APIt.Request | null) {
  const visitor = accessLevelRequest.visitors.find(v => v.id === accessLevel.visitor_id);
  debug(`populatePOCAutoApproveCalls() visitor is ${JSON.stringify(visitor)}`);
  let visitorType = '';
  if (visitor)
    visitorType = (await queryLookupTypeValue(visitor.visitor_type_id))?.value || '';
  debug(`populatePOCAutoApproveCalls() visitorType is ${JSON.stringify(visitorType)}`);
  const action: IVisitorAccessLevelWithAction = {
    __typename: 'VisitorAccessLevel',
    ...accessLevelRequest.request,
    ...accessLevel,
    action: { label: 'POCApprove', value: 'pocapprove' },
    created: '',
    dates_updated: false,
    person_id: visitor?.person_id || '',
    request_id: accessLevelRequest.request.id,
    requestor_id: accessLevelRequest.request.requestor_id,
    requestor_source_system_id: accessLevelRequest.request.requestor_source_system_id,
    updated: '',
    updated_by: accessLevelRequest.request.requestor_id,
    visitor_type: visitorType,
  };

  try {
    handlePOCApprovalCalls.push(submitVisitorAccessLevelActions(
      {
        actions: [action],
        approverId: accessLevelRequest.request.requestor_id,
      }));
  } catch (error) {
    debug(`populatePOCAutoApproveCalls() sNSPublishAccessLevelRequestPOCApproved error is ${JSON.stringify(error)}`);
    throw error;
  }
}

export async function populateApproverGroupCalls(approverGroup: string, request: APIt.Request,  disableAutoApproval: boolean, autoApprove: boolean, createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[], approverSourceSystemId: string, visitorAccessLevel: APIt.VisitorAccessLevel, approvedAccessLevelStatusCodeId: string, pendingApprovalAccessLevelStatusCodeId: string, createVisitorAccessLevelApprovalCalls: Promise<APIt.VisitorAccessLevelApproval | null>[]) {
  debug(`populateApproverGroupCalls() approverGroup is ${approverGroup}`);
  let approvers = await getApprovers(approverGroup);
  debug(`populateApproverGroupCalls() approvers is ${JSON.stringify(approvers)}`);
  if (approvers.includes(request.requestor_id) && !disableAutoApproval) {
    autoApprove = true;
    approvers = [request.requestor_id];
  }
  for (let approver of approvers) {
    populateApproverCalls(approver, createdVisitorAccessLevelApprovals, approverSourceSystemId, visitorAccessLevel, request, autoApprove, approvedAccessLevelStatusCodeId, pendingApprovalAccessLevelStatusCodeId, createVisitorAccessLevelApprovalCalls);
  }
  debug(`populateApproverGroupCalls() autoApprove is ${autoApprove}`);
  return autoApprove;
}

export function populateApproverCalls(approver: string, createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[], approverSourceSystemId: string, visitorAccessLevel: APIt.VisitorAccessLevel, request: APIt.Request, autoApprove: boolean, approvedAccessLevelStatusCodeId: string, pendingApprovalAccessLevelStatusCodeId: string, createVisitorAccessLevelApprovalCalls: Promise<APIt.VisitorAccessLevelApproval | null>[]) {
  debug(`populateApproverCalls() approver is ${approver}`);
  if (createdVisitorAccessLevelApprovals.find((a) => (a.approver_id == approver
    && a.approver_source_system_id == approverSourceSystemId
    && a.visitor_access_level_id == visitorAccessLevel.id)) == undefined) {
    const id = uuid.v4();
    const createVisitorAccessLevelApprovalInput: APIt.CreateVisitorAccessLevelApprovalInput = {
      approver_id: approver,
      approver_source_system_id: approverSourceSystemId,
      created_by: request.created_by,
      id: id,
      visitor_access_level_id: visitorAccessLevel.id,
      status_code_id: autoApprove ? approvedAccessLevelStatusCodeId : pendingApprovalAccessLevelStatusCodeId,
    };
    createdVisitorAccessLevelApprovals.push(createVisitorAccessLevelApprovalInput);
    createVisitorAccessLevelApprovalCalls.push(createVisitorAccessLevelApproval(createVisitorAccessLevelApprovalInput));
  }
}

export function populatePOCApproverCalls(approver: string, createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[], approverSourceSystemId: string, visitorAccessLevel: APIt.VisitorAccessLevel, accessLevelRequest: ISubmitAccessLevelRequest, autoApprove: boolean, pendingPOCApprovalAccessLevelStatusCodeId: string, pendingApprovalAccessLevelStatusCodeId: string, createVisitorAccessLevelApprovalCalls: Promise<APIt.VisitorAccessLevelApproval | null>[]) {
  debug(`populatePOCApproverCalls() approver is ${approver}`);
  if (createdVisitorAccessLevelApprovals.find((a) => (a.approver_id == approver
    && a.approver_source_system_id == approverSourceSystemId
    && a.visitor_access_level_id == visitorAccessLevel.id)) == undefined) {
    const id = uuid.v4();
    const createVisitorAccessLevelApprovalInput: APIt.CreateVisitorAccessLevelApprovalInput = {
      approver_id: approver,
      approver_source_system_id: approverSourceSystemId,
      created_by: accessLevelRequest.request.created_by,
      id: id,
      visitor_access_level_id: visitorAccessLevel.id,
      status_code_id: autoApprove ? pendingApprovalAccessLevelStatusCodeId : pendingPOCApprovalAccessLevelStatusCodeId,
    };
    createdVisitorAccessLevelApprovals.push(createVisitorAccessLevelApprovalInput);
    createVisitorAccessLevelApprovalCalls.push(createVisitorAccessLevelApproval(createVisitorAccessLevelApprovalInput));
  }
}

async function createVisitorAndAssets(visitor: ICreateVisitorInputWithAssets) {
  const createVisitorInput = { ...visitor };
  delete createVisitorInput.assets;
  debug(`createVisitorAndAssets() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);
  const createdVisitor = await createVisitor(createVisitorInput);
  debug(`createVisitorAndAssets() createdVisitor is ${JSON.stringify(createdVisitor)}`);
  if (visitor.assets) {
    for (let asset of visitor.assets) {
      const createdVisitorAsset = await createVisitorAsset(asset);
      debug(`createVisitorAndAssets() createdVisitorAsset is ${JSON.stringify(createdVisitorAsset)}`);
    };
  }
}


async function createAndSendAssessmentLink(visitor: ICreateVisitorInputWithAssets, accessLevels: APIt.CreateVisitorAccessLevelInput[]) {
  const sitesDelimited = accessLevels
  .map(level => level.site_id)
  .filter((value, index, self) => self.indexOf(value) === index)
  .join(';');
  const vceDefaultValues = (await queryLookupTypeValueForTypeAndDescription(
    LookupTypes.WelcomeApplicationSettings,
    WelcomeApplicationSettings.VCEDefaultValues)).value;
  debug(`createAndSendAssessmentLink() vceDefaultValues is ${JSON.stringify(vceDefaultValues)}`);
  const vceDefaultValuesArray = vceDefaultValues.split(',');
  const candidateAssessmentParams: CandidateAssessmentParams = {
    candidateDocumentValue: visitor.id,
    candidateDocumentTypeId: vceDefaultValuesArray.length > 0 ? vceDefaultValuesArray[0] : '1801439851250380784',
    positionId: vceDefaultValuesArray.length > 1 ? vceDefaultValuesArray[1] : '2377900761014759554',
    scheduledDate: getTomorrowDate(),
    testLanguageId: vceDefaultValuesArray.length > 2 ? vceDefaultValuesArray[2] : '1',
    firstName: sitesDelimited,
    lastName: visitor.last_name!,
    gender: vceDefaultValuesArray.length > 3 ? vceDefaultValuesArray[3] : 'male',
    visitor_id: visitor.id
  };
  const candidateTest = await createCandidateAssessment(candidateAssessmentParams);
  const createVisitorAssessmentInput: APIt.CreateVisitorAssessmentInput = {
    id: uuid.v4(),
    test_link: candidateTest.linkForStartingTest,
    scheduled_date: candidateAssessmentParams.scheduledDate,
    test_language_id: parseInt(candidateAssessmentParams.testLanguageId),
    unique_visitor_id: visitor.unique_visitor_id!,
    created_by: visitor.created_by,
    document_number: candidateAssessmentParams.candidateDocumentValue,
    candidate_test_id: candidateTest.candidateTestId,
    visitor_id: visitor.id
  };
  debug(`createAndSendAssessmentLink() createVisitorAssessmentInput is ${JSON.stringify(createVisitorAssessmentInput)}`);
  const createdVisitorAssessment = await createVstrAssessment(createVisitorAssessmentInput);
  debug(`createAndSendAssessmentLink() createdVisitorAssessment is ${JSON.stringify(createdVisitorAssessment)}`);
  if (createdVisitorAssessment) {
    debug(`createAndSendAssessmentLink() createdVisitorAssessment exists and  is ${JSON.stringify(createdVisitorAssessment)}`);
    try {
      const response = await API.graphql(graphqlOperation(sNSPublishVendorTestLinkGenerated,
        {
          id: createdVisitorAssessment.id!
        })) as GraphQLResult<APIt.SNSPublishVendorTestLinkGeneratedQuery>;
      if (response && response.data && response.data.SNSPublishVendorTestLinkGenerated) {
        debug(`createAndSendAssessmentLink() SNSPublishAccessLevelRequestCreated response is ${JSON.stringify(response)}`);
      }
    } catch (error) {
      console.error(`createAndSendAssessmentLink() SNSPublishAccessLevelRequestCreated error is ${JSON.stringify(error)}`);
      throw error;
    }
  }
}

export async function getLatestAssessment(unique_visitor_id: string){
  const existingVisitorAssessments = await getExistingVisitorAssessments(unique_visitor_id!);
  debug(`getLatestAssessment() existingVisitorAssessments is ${JSON.stringify(existingVisitorAssessments)}`);
  const latestAssessment = existingVisitorAssessments.reduce((latest, current) => {
    if (!latest)
      return current;
    const latestDate = new Date(latest.created || '');
    const currentDate = new Date(current.created || '');
    return currentDate > latestDate ? current : latest;
  }, null as APIt.VisitorAssessment | null);
  debug(`latestAssessment is ${JSON.stringify(latestAssessment)}`);
  return latestAssessment;
}
export async function checkAssessmentValidity(latestAssessment: APIt.VisitorAssessment) {
  const testValidDays = (await queryLookupTypeValueForTypeAndDescription(
    LookupTypes.WelcomeApplicationSettings,
    WelcomeApplicationSettings.VCETestValidityDuration)).value;
    debug(`checkAssessmentValidity() testValidDays is ${testValidDays}`);
    debug(`checkAssessmentValidity() latestAssessment is ${JSON.stringify(latestAssessment)}`);
  if(!latestAssessment?.result_received_date || !latestAssessment?.integrity_score)
    return false;
  const isTestResultValid =  (() => {
    const resultDate = new Date(latestAssessment.result_received_date);
    const today = new Date();
    debug(`checkAssessmentValidity() resultDate is ${resultDate} and today is ${today}`);
    const daysDiff = Math.floor((today.getTime() - resultDate.getTime()) / (1000 * 60 * 60 * 24));
    debug(`checkAssessmentValidity() daysDiff is ${daysDiff} and testValidDays is ${testValidDays}`);
    return daysDiff <= parseInt(testValidDays);
  })();
  return isTestResultValid;
}

export async function checkAssessmentPassed(latestAssessment: APIt.VisitorAssessment) {
  const integrityThreshold = parseFloat((await queryLookupTypeValueForTypeAndDescription(
    LookupTypes.WelcomeApplicationSettings,
    'VCE Integrity Threshold')).value);
  const integrityScore = latestAssessment.integrity_score;
  if(!integrityScore)
    return false;
  return integrityScore <= integrityThreshold;
}

async function ensureUniqueVisitor(visitor: ICreateVisitorInputWithAssets) {
  if (!visitor.unique_visitor_id) {
    const uniqueVisitor = await createUniqVisitor({
      id: uuid.v4(),
      created_by: visitor.created_by,
      first_name: visitor.first_name!,
      last_name: visitor.last_name!,
      email_address: visitor.email!,
      company_name: visitor.company!,
      phone_number: visitor.phone_number!,
    });
    visitor.unique_visitor_id = uniqueVisitor?.id;
  }else{
    const uniqueVisitor = await updateUniqVisitor({
      id: visitor.unique_visitor_id,
      updated_by: visitor.created_by,
      first_name: visitor.first_name!,
      last_name: visitor.last_name!,
      email_address: visitor.email!,
      company_name: visitor.company!,
      phone_number: visitor.phone_number!,
    });
  }
}


async function isVCEDisabledForSelectedCountries(accessLevels: APIt.CreateVisitorAccessLevelInput[]): Promise<boolean> {
  debug(`isVCEDisabledForSelectedCountries() accessLevels is ${JSON.stringify(accessLevels)}`);
  const sites = await querySites(true);
  debug(`isVCEDisabledForSelectedCountries() sites is ${JSON.stringify(sites)}`);
  const siteCountryMap = sites.reduce((map, site) => {
    if (site.CountryName) {
      map[site.SiteCode] = site.CountryName;
    }
    return map;
  }, {} as { [key: string]: string });
  debug(`isVCEDisabledForSelectedCountries() siteCountryMap is ${JSON.stringify(siteCountryMap)}`);
  const countries: Set<string> = new Set<string>();
  for (const accessLevel of accessLevels) {
    debug(`isVCEDisabledForSelectedCountries() accessLevel is ${JSON.stringify(accessLevel)}`);
    debug(`isVCEDisabledForSelectedCountries() accessLevel.site_id is ${JSON.stringify(accessLevel.site_id)}`);
    if (accessLevel.site_id ){
      debug(`isVCEDisabledForSelectedCountries() siteCountryMap[accessLevel.site_id] is ${JSON.stringify(siteCountryMap[accessLevel.site_id])}`);
      if(siteCountryMap[accessLevel.site_id]){
        countries.add(siteCountryMap[accessLevel.site_id].toLowerCase());
      } 
    }
  }
  debug(`isVCEDisabledForSelectedCountries() countries is ${JSON.stringify(Array.from(countries))}`);
  const vceDisabledCountriesVal = (await queryLookupTypeValueForTypeAndDescription(
    LookupTypes.WelcomeApplicationSettings,
    WelcomeApplicationSettings.VCEDisabledCountries)).value;
  debug(`isVCEDisabledForSelectedCountries() vceDisabledCountriesVal is ${JSON.stringify(vceDisabledCountriesVal)}`);
  if(vceDisabledCountriesVal.length > 0 && countries.size > 0){
    const vceDisabledCountries = vceDisabledCountriesVal.split(',');
    debug(`isVCEDisabledForSelectedCountries() vceDisabledCountries is ${JSON.stringify(vceDisabledCountries)}`);
    return vceDisabledCountries.some(country => countries.has(country.toLocaleLowerCase()));
  }
    return false;
}

export async function checkVendorsExceedingAllowedDays(accessLevels: IVendorAccessDetailsRecord[], vendors: TVendor[]):Promise<boolean> {
  debug('checkExceedsAlloweDaysErrors()');
  const msPerDay = 24*60*60*1000;
  const rollingPeriod = await queryLookupTypeValueForTypeAndDescription(
    LookupTypes.WelcomeApplicationSettings,
    WelcomeApplicationSettings.RollingPeriod);
  const maxAvailableDays = await queryLookupTypeValueForTypeAndDescription(
    LookupTypes.WelcomeApplicationSettings,
    WelcomeApplicationSettings.AvailableDaysInRollingPeriod);
  debug('rollingPeriod '+JSON.stringify(rollingPeriod));
  debug('maxAvailableDays '+JSON.stringify(maxAvailableDays));
    if (!rollingPeriod?.value || !maxAvailableDays?.value) {
      throw new Error('Missing configuration values');
    }
  const proposedIntervals = makeMergedList(accessLevels.map((al)=>({
    start_date: new Date(al.validFrom!),
    end_date: new Date(al.validThrough!)
  })), []);
  debug('proposedIntervals '+JSON.stringify(proposedIntervals));
  if(proposedIntervals.length == 0)
    throw new Error('No access levels provided');
  //first let's check if current values are within the rules
  if(exceedsAllowedDays(proposedIntervals, parseInt(rollingPeriod.value), parseInt(maxAvailableDays.value)))
    return true;
  const fromDate = new Date(proposedIntervals[0].end_date.getTime()-parseInt(rollingPeriod.value)*msPerDay);
  const throughDate = proposedIntervals[proposedIntervals.length-1].end_date;
  const vendorsExceeding: TVendor[] = [];
  //now we check for each vendor 
  for(const vendor of vendors){
    debug('vendor '+JSON.stringify(vendor));
    if(!vendor.unique_visitor_id) continue;
    let existingIntervals = await queryVisitorAccessLevelsByUniqueVisitor(vendor.unique_visitor_id!, fromDate.toISOString(), throughDate.toISOString());
    debug('existingIntervals '+JSON.stringify(existingIntervals));
    if(!existingIntervals || existingIntervals.length == 0) continue;
    existingIntervals = existingIntervals?.filter((al)=>al.status != ApprovalStatus.Cancelled);
    if(existingIntervals && existingIntervals.length > 0){
      const visitorIntervals:Period[] = existingIntervals.map((al)=>({
        start_date: new Date(al.start_date!),
        end_date: new Date(al.end_date!)
      }));
      const mergedIntervals = makeMergedList(visitorIntervals, proposedIntervals);
      debug('mergedIntervals '+JSON.stringify(mergedIntervals));
      if(exceedsAllowedDays(mergedIntervals, parseInt(rollingPeriod.value), parseInt(maxAvailableDays.value)))
        return true;
    }
    
  }
  return false;
}

interface Period {
  start_date: Date;
  end_date: Date;
}

interface PeriodWithDays {
  period: Period;
  daysInWindow: number;
}

function exceedsAllowedDays(
  periods: Period[],
  rollingPeriod: number,  // rolling window in days
  maxAvailableDays: number
): boolean {
  // Sort periods by end_date to process chronologically
  const sortedPeriods = [...periods].sort((a, b) =>
    a.end_date.getTime() - b.end_date.getTime()
  );

  let totalDays = 0;
  const periodQueue: Queue<PeriodWithDays> = new Queue<PeriodWithDays>();
  const msPerDay = 1000 * 60 * 60 * 24;
  debug('exceedsAllowedDays() periods '+JSON.stringify(periods));
  for (const period of sortedPeriods) {
    // Calculate rolling window start date based on current period's end date
    const rollingWindowStart = new Date(period.end_date);
    rollingWindowStart.setDate(rollingWindowStart.getDate() - rollingPeriod);

    // Remove periods that are now outside the rolling window
    while (periodQueue.length > 0 && 
           periodQueue.front.period.end_date.getTime() <= rollingWindowStart.getTime()) {
      totalDays -= periodQueue.front.daysInWindow;
      periodQueue.dequeue();
      debug('exceedsAllowedDays() after subtraction totalDays '+totalDays);
    }

    // Calculate days within rolling window for current period
    const periodStart = new Date(Math.max(
      period.start_date.getTime(),
      rollingWindowStart.getTime()
    ));
    debug('exceedsAllowedDays() periodStart '+periodStart);
    debug('exceedsAllowedDays() period.end_date '+period.end_date);
    const daysInWindow = Math.ceil(
      (period.end_date.getTime() - periodStart.getTime()) / msPerDay
    );
    debug('exceedsAllowedDays() daysInWindow '+daysInWindow);

    // Add current period to queue and update total
    periodQueue.enqueue({
      period,
      daysInWindow
    });
    totalDays += daysInWindow;
    debug('exceedsAllowedDays() after addition totalDays '+totalDays);

    // Check if we exceed maximum days
    if (totalDays > maxAvailableDays) {
      return true;
    }
  }
  
  return false;
}


function makeMergedList(accessLevels: Period[], existingIntervals: Period[]): Period[] {
  // Convert access levels to intervals
  debug('makeMergedList() accessLevels '+JSON.stringify(accessLevels));
  debug('makeMergedList() existingIntervals '+JSON.stringify(existingIntervals));
  const intervals: Period[] = [
    ...accessLevels.map(al => ({
      start_date: new Date(al.start_date!),
      end_date: new Date(al.end_date!)
    })),
    ...existingIntervals
  ];
  debug('makeMergedList() intervals '+JSON.stringify(intervals));
  intervals.sort((a, b) => a.start_date.getTime() - b.start_date.getTime());

  const mergedIntervals: Period[] = [];

  if (intervals.length === 0) return mergedIntervals;

  let currentInterval = intervals[0];

  for (let i = 1; i < intervals.length; i++) {
    // If current interval overlaps with next interval
    if (intervals[i].start_date <= currentInterval.end_date) {
      // Merge by taking later end date
      currentInterval.end_date = new Date(Math.max(
        currentInterval.end_date.getTime(),
        intervals[i].end_date.getTime()
      ));
    } else {
      // No overlap, add current interval and move to next
      mergedIntervals.push(currentInterval);
      currentInterval = intervals[i];
    }
  }

  // Add the last interval
  mergedIntervals.push(currentInterval);

  return mergedIntervals;
}