import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { TVisitor, TVendor, VendorSchema, VisitorSchema } from 'src/types';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { useQuery } from '@tanstack/react-query';
import { queryLookupTypeValues, queryVisitorAssetTypes } from '../../utils';
import * as APIt from 'src/API';
import { debug } from 'src/utils/commonUtils';

export interface IEditVisitorProps{
  editVisitorCallback: Function;
  editVendor: boolean;
  cancelEditVisitorCallback: Function;
  id: number;
  visitor: TVisitor | TVendor;
  visitorAssets: APIt.VisitorAsset[];
}

interface VisitorType {
  label: string;
  value: string;
}

export default function EditVisitor(props: IEditVisitorProps) {
  debug(`EditVisitor() props is ${JSON.stringify(props)}`);

  const [errorText, setErrorText] = useState<string>();
  const [expandAssets, setExpandAssets] = useState<boolean>(false);
  const [visitor, setVisitor] = useState<Partial<TVisitor>>(props.visitor);
  const [visitorAssets, setVisitorAssets] = useState<APIt.VisitorAsset[]>(props.visitorAssets);
  const [visitorTypes, setVisitorTypes] = useState<VisitorType[]>([]);

  const [bundle, isBundleLoading] = useBundle('components.common.Visitor.EditVisitor');

  const firstNameInputRef = useRef<HTMLInputElement>(null);

  const editVisitorButtonHandler = async () => {
    debug(`EditVisitor() editVisitorButtonHandler() visitor is ${JSON.stringify(visitor)}`);
    const parseResults = props.editVendor ? await VendorSchema.safeParseAsync(visitor) : await VisitorSchema.safeParseAsync(visitor);
    debug(`EditVisitor() editVisitorButtonHandler() parseResults is ${JSON.stringify(parseResults)}`);
    if (parseResults.success) {
      props.editVisitorCallback({
        ...visitor,
        assets: visitorAssets,
      });
      setVisitor(
        {
          company: undefined,
          emailAddress: '',
          phoneNumber: '',
          id: props.id
        });
    }
    if (!parseResults.success) {
      let errors = '';
      for (let issue of parseResults.error.issues) {
        errors += `${bundle.getMessage(issue.message)}; `;
      }
      setErrorText(errors);
    }
  };

  const cancelEditVisitorButtonHandler = () => {
    setVisitor(props.visitor);
    props.cancelEditVisitorCallback();
  };

  const visitorAssetTypesQuery = useQuery({
    queryKey: ['editVisitorAssetTypes'],
    queryFn: async () => await queryVisitorAssetTypes(),
    retry: 3
  });

  useEffect(() => {
    (async () => {
      const lookupTypeValues = await queryLookupTypeValues('Visitor Type');
      debug(`AddVisitor() useEffect() lookupTypeValues is ${JSON.stringify(lookupTypeValues)}`);
      if (lookupTypeValues) {
        const visitorTypes = lookupTypeValues
          .filter((visitorType: APIt.LookupTypeValue) =>
            !['Employee', 'Unescorted Vendor'].includes(visitorType.value))
          .map((visitorType: APIt.LookupTypeValue) => {
            return {
              label: visitorType.value,
              value: visitorType.id
            };
          });

        setVisitorTypes(visitorTypes);
      }
    })();
  }, []);
  

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      header={
        <Header>
          {props.editVendor ? bundle.getMessage('edit-vendor') : bundle.getMessage('edit-visitor')}
        </Header>
      }
      onDismiss={() => props.cancelEditVisitorCallback()}
      size='large'
      visible={true}
    >
      <form onSubmit={async e => {
        e.preventDefault();
        await editVisitorButtonHandler();
      }}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                data-testid='EditVisitorCancelButton'
                formAction='none'
                variant='normal'
                onClick={cancelEditVisitorButtonHandler}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='EditVisitorEditVisitorButton'
                variant='primary'
              >
                {bundle.getMessage('save')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        >
          <Grid
            gridDefinition={
              [
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } }
              ]}
          >
            <FormField label={bundle.getMessage('visitor-type')}>
              <Select
                data-testid='EditVisitorTypeSelect'
                onChange={({ detail }) => {
                  setVisitor({
                    ...visitor,
                    visitor_type_id: detail.selectedOption.value,
                    visitor_type: detail.selectedOption.label
                  });
                }}
                selectedOption={
                  visitorTypes.find(type => type.value === visitor.visitor_type_id) || null
                }
                options={visitorTypes}
              />
            </FormField>
            <FormField label={bundle.getMessage('first-name')}>
              <Input
                autoFocus
                data-testid='EditVisitorFirstNameInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      firstName: detail.value == '' ? undefined : detail.value
                    });
                }}
                ref={firstNameInputRef}
                value={visitor?.firstName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('last-name')}>
              <Input
                data-testid='EditVisitorLastNameInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      lastName: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={visitor?.lastName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('company')}>
              <Input
                data-testid='EditVisitorCompanyInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      company: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={visitor?.company || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('email-address')}>
              <Input
                data-testid='EditVisitorEmailAddressInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      emailAddress: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={visitor?.emailAddress || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('phone-number')}>
              <Input
                data-testid='EditVisitorPhoneNumberInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      phoneNumber: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={visitor?.phoneNumber || ''}
              />
            </FormField>
          </Grid>
          <ExpandableSection
            data-testid='EditVisitorShowAssetsToggle'
            expanded={expandAssets}
            header={expandAssets ? bundle.getMessage('hide-assets') : `${bundle.getMessage('show-assets')} (${visitorAssets.length})` }
            onChange={() => setExpandAssets(!expandAssets)}
          >
            <VisitorAssetsTablePanel
             isTableLoading={false}
             readOnly={false}
             siteCode=''
             setVisitorAssets={setVisitorAssets}
             username=''
             visitorAssets={visitorAssets}
             visitorAssetTypesQuery={visitorAssetTypesQuery}
             visitorId=''
           />
          </ExpandableSection>
        </Form>
      </form>
    </Modal>);
}