import React, { useRef, useState } from 'react';
import {
  Button,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { TVisitor, TVendor, VendorSchema, VisitorSchema } from 'src/types';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { useQuery } from '@tanstack/react-query';
import * as APIt from 'src/API';
import { debug } from 'src/utils/commonUtils';
import { queryVisitorAssetTypes } from 'src/components/utils';

export interface IAddVisitorProps{
  addVisitorCallback: Function;
  addVendor: boolean;
  cancelAddVisitorCallback: Function;
  id: number;
}

export default function AddVendor(props: IAddVisitorProps) {
  debug(`AddVisitor() props is ${JSON.stringify(props)}`);

  const [errorText, setErrorText] = useState<string>();
  const [expandAssets, setExpandAssets] = useState<boolean>(false);
  const [visitor, setVisitor] = useState<Partial<TVendor>>(
    {
      company: props.addVendor ? undefined : null,
      emailAddress: '',
      phoneNumber: '',
      id: props.id
    });
  const [visitorAssets, setVisitorAssets] = useState<APIt.VisitorAsset[]>([]);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVendorAccess.Vendors.AddVendor');

  const firstNameInputRef = useRef<HTMLInputElement>(null);

  const addVisitorButtonHandler = async () => {
    debug(`AddVisitor() addVisitorButtonHandler() visitor is ${JSON.stringify(visitor)}`);
    const parseResults = await VendorSchema.safeParseAsync(visitor);
    debug(`AddVisitor() addVisitorButtonHandler() parseResults is ${JSON.stringify(parseResults)}`);
    if (parseResults.success) {
      props.addVisitorCallback({
        ...visitor,
        assets: visitorAssets,
      });
      setVisitor(
        {
          company: props.addVendor ? undefined : null,
          emailAddress: '',
          phoneNumber: '',
          id: props.id
        });
    }
    if (!parseResults.success) {
      let errors = '';
      for (let issue of parseResults.error.issues) {
        errors += `${bundle.getMessage(issue.message)}; `;
      }
      setErrorText(errors);
    }
  };

  const cancelAddVisitorButtonHandler = () => {
    setVisitor(
      {
        company: props.addVendor ? undefined : null,
        emailAddress: '',
        phoneNumber: '',
        id: props.id
      });
    props.cancelAddVisitorCallback();
  };

  const visitorAssetTypesQuery = useQuery({
    queryKey: ['addVisitorAssetTypes'],
    queryFn: async () => await queryVisitorAssetTypes(),
    retry: 3
  });

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      header={
        <Header>
          {props.addVendor ? bundle.getMessage('add-vendor') : bundle.getMessage('add-visitor')}
        </Header>
      }
      onDismiss={() => props.cancelAddVisitorCallback()}
      size='large'
      visible={true}
    >
      <form onSubmit={async e => {
        e.preventDefault();
        await addVisitorButtonHandler();
      }}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                data-testid='AddVisitorCancelButton'
                formAction='none'
                variant='normal'
                onClick={cancelAddVisitorButtonHandler}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='AddVisitorAddVisitorButton'
                variant='primary'
                onClick={async () => await addVisitorButtonHandler()}
              >
                {props.addVendor ? bundle.getMessage('add-vendor') : bundle.getMessage('add-visitor')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        >
          <Grid
            gridDefinition={
              [
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } }
              ]}
          >
            <FormField label={bundle.getMessage('first-name')}>
              <Input
                autoFocus
                data-testid='AddVisitorFirstNameInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      firstName: detail.value == '' ? undefined : detail.value
                    });
                }}
                ref={firstNameInputRef}
                value={visitor?.firstName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('last-name')}>
              <Input
                data-testid='AddVisitorLastNameInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      lastName: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={visitor?.lastName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('company')}>
              <Input
                data-testid='AddVisitorCompanyInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      company: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={visitor?.company || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('email-address')}>
              <Input
                data-testid='AddVisitorEmailAddressInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      emailAddress: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={visitor?.emailAddress || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('phone-number')}>
              <Input
                data-testid='AddVisitorPhoneNumberInput'
                onChange={({detail}) => {
                  setVisitor(
                    {
                      ...visitor,
                      phoneNumber: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={visitor?.phoneNumber || ''}
              />
            </FormField>
          </Grid>
          <ExpandableSection
            data-testid='AddVisitorShowAssetsToggle'
            expanded={expandAssets}
            header={expandAssets ? bundle.getMessage('hide-assets') : `${bundle.getMessage('show-assets')} (${visitorAssets.length})` }
            onChange={() => setExpandAssets(!expandAssets)}
          >
            <VisitorAssetsTablePanel
             isTableLoading={false}
             readOnly={false}
             siteCode=''
             setVisitorAssets={setVisitorAssets}
             username=''
             visitorAssets={visitorAssets}
             visitorAssetTypesQuery={visitorAssetTypesQuery}
             visitorId=''
           />
          </ExpandableSection>
        </Form>
      </form>
    </Modal>);
}