import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  CollectionPreferences,
  Container,
  FormField,
  Grid,
  Icon,
  Input,
  Pagination,
  RadioGroup,
  RadioGroupProps,
  SpaceBetween,
  Spinner,
  SplitPanel,
  Table,
  TableProps,
  Textarea,
  TextFilter,
  Toggle,
  ToggleProps} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from '../../../API';
import { useBundle } from '@amzn/react-arb-tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryVisitorAccessLevelApprovals, submitVisitorAccessLevelActions } from './utils';
import SubmitActions from './SubmitActions';
import ManageAccessRequestDetailsTablePanel from './DetailsTablePanel';
import { ApprovalStatus } from 'src/constants/Constants';
import CsvDownloadButton from 'src/components/common/CsvDownloadButton';
import { debug } from 'src/utils/commonUtils';

export enum Actions {
  approve = 'approve',
  deny = 'deny',
  wait = 'wait',
  editDates = 'edit-dates',
  pocApprove = 'pocapprove'
}

export interface CancelEditAccessRequestInterface {
  (): void;
}

export interface EditAccessRequestInterface {
  (): void;
}
export interface RefreshCallBackInterface {
  (): Promise<void>;
}

export interface IVisitorAccessLevelWithAction extends APIt.VisitorAccessLevel {
  action?: RadioGroupProps.RadioButtonDefinition;
  denialReason?: string;
  invalidDates?: boolean;
  missingActiveBadge?: boolean;
  notes?: string;
}

export interface ManageAccessRequestsTablePanelPropsInterface {
  admin: boolean;
  isTableLoading: boolean;
  manageRequestsAccessLevels: IVisitorAccessLevelWithAction[];
  manageVisitorAccessLevelQueryAdmin: boolean;
  pageSize: number;
  refreshCallback: RefreshCallBackInterface;
  setPageSizePrefCallback: Function;
  setSplitPanelCallback: Function;
  setSplitPanelOpenCallback: Function;
  setStatusFilterCallback: Function;
  username: string;
}

export default function ManageAccessRequestsTablePanel(props: ManageAccessRequestsTablePanelPropsInterface ) {
  debug(`ManageAccessRequestsTablePanel() props is ${JSON.stringify(props)}`);

  const actionColumnMinWidth = 230; //4 actions icons stay in one line
  const actionColumnVerboseMinWidth = 352; //4 actions by words: approve, deny, wait, edit dates
  const [actionColumnWidth, setActionColumnWidth] = useState<number>(actionColumnMinWidth);
  const [allItems, setAllItems] = useState<IVisitorAccessLevelWithAction[]>(props.manageRequestsAccessLevels);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [pageSize, setPageSize ] = useState<number>(props.pageSize);
  const [queryAllRequests, setQueryAllRequests] = useState<boolean>(false);
  const [queryAllPendingRequestsAdmin, setQueryAllPendingRequestsAdmin] = useState<boolean>(props.manageVisitorAccessLevelQueryAdmin);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IVisitorAccessLevelWithAction | null>(null);
  const [showSubmissionFailureMessage, setShowSubmissionFailureMessage] = useState<boolean>(false);
  const [showSubmissionSuccessMessage, setShowSubmissionSuccessMessage] = useState<boolean>(false);
  const [showSubmitActions, setShowSubmitActions] = useState<boolean>(false);
  const [submissionFailureErrorText, setSubmissionFailureErrorText] = useState<string>('');

  const [approvedLabel, setApprovedLabel] = useState<React.ReactElement | string>('');
  const [deniedLabel, setDeniedLabel] = useState<React.ReactElement | string>('');
  const [waitLabel, setWaitLabel] = useState<React.ReactElement | string>('');
  const [editDatesLabel, setEditDatesLabel] = useState<React.ReactElement | string>('');

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.TablePanel');

  const queryClient = useQueryClient();

  const manageVisitorAccessLevelsQueryState = queryClient.getQueryState(['manageVisitorAccessLevels']);

  const visitorAccessLevelApprovalsQuery = useQuery({
    queryKey: ['accessLevelApprovals'],
    queryFn: () => queryVisitorAccessLevelApprovals(selectedItem?.id),
    retry: 3
  });

  const visitorAccessLevelSubmitActionsMutation = useMutation({
    mutationFn: submitVisitorAccessLevelActions,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['manageVisitorAccessLevels'] })
      setShowSubmissionSuccessMessage(true);
      setShowSubmitActions(false);
      setSelectedItem(null);
    },
    onError: (error: any) => {
      setShowSubmissionFailureMessage(true);
      debug(`visitorAccessLevelSubmitActionsMutation() error is ${error} ${JSON.stringify(error)} typeof error is ${typeof error} error.message is ${error.message}`);
      (typeof error == 'object') ? setSubmissionFailureErrorText(error.message) : setSubmissionFailureErrorText(error.toString());
      setShowSubmitActions(false);
    }
  });

  const refresh = async () => {
    await visitorAccessLevelApprovalsQuery.refetch();
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, allPageItems } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-access-requests-found') : 'No Access Requests Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    await props.refreshCallback();
    setRefreshing(false);
  };

  const setAction = (item: IVisitorAccessLevelWithAction, value: any) => {
    const newItems = [...allItems];
    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].id == item.id) {
        newItems[i].action = { value, label: bundle.getMessage(value)}
        break;
      }
    };
    setAllItems(newItems);
  };

  const resetActions = () => {
    const newItems = [...allItems];
    for (let i = 0; i < newItems.length; i++) {
      delete newItems[i].action;
    };
    setAllItems(newItems);
  };

  const submitActions = async (actions: IVisitorAccessLevelWithAction[]) => {
    debug(`ManageAccessRequests() submitActions() actions is ${JSON.stringify(actions)}`);
    visitorAccessLevelSubmitActionsMutation.mutate({ actions, approverId: props.username});
    props.refreshCallback();
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const Details = () => {
    return(
      <Grid gridDefinition={[{colspan: 2}, {colspan: 2}, {colspan: 2}, {colspan: 2}, {colspan: 3}]}>
        <FormField label={bundle.getMessage('requested-by')} >
          <Input value={selectedItem?.requestor_id || ''} disabled/>
        </FormField>
        {selectedItem?.email
        &&
        <FormField label={bundle.getMessage('email-address')} >
          <Input value={selectedItem?.email || ''} disabled/>
        </FormField>
        }
        {selectedItem?.company
        &&
        <FormField label={bundle.getMessage('company')} >
          <Input value={selectedItem?.company || ''} disabled/>
        </FormField>
        }
        <FormField label={bundle.getMessage('reason-for-access-request')} >
          <Textarea value={selectedItem?.reason || ''} disabled/>
        </FormField>
      </Grid>
    );
  };

  const columnDefinitions = (): TableProps.ColumnDefinition<IVisitorAccessLevelWithAction>[] => {
    return(
      [
        ...ColumnDefinitions,
        {
          id: 'action',
          header: bundle.getMessage('action'),
          cell: (item: IVisitorAccessLevelWithAction) =>
            <SpaceBetween size='s' direction='horizontal'>
              <div title={bundle.getMessage(Actions.approve)}>
                <RadioGroup
                  items={
                    [{ value: item.status == ApprovalStatus.PendingApproval ? Actions.approve : Actions.pocApprove, label: approvedLabel, disabled: !(item.status == ApprovalStatus.PendingApproval  || item.status == ApprovalStatus.PendingPOCApproval)}]
                  }
                  onChange={() => setAction(item, item.status == ApprovalStatus.PendingApproval ? Actions.approve : Actions.pocApprove)}
                  value={item.action?.value || null}
                />
              </div>
              <div title={bundle.getMessage(Actions.deny)}>
                <RadioGroup
                  items={
                    [{ value: Actions.deny, label: deniedLabel, disabled: !(item.status == ApprovalStatus.PendingApproval  || item.status == ApprovalStatus.PendingPOCApproval) }]
                  }
                  onChange={() => setAction(item, Actions.deny)}
                  value={item.action?.value || null}
                />
              </div>
              <div title={bundle.getMessage(Actions.wait)}>
                <RadioGroup
                  items={
                    [{ value: Actions.wait, label: waitLabel, disabled: !(item.status == ApprovalStatus.PendingApproval  || item.status == ApprovalStatus.PendingPOCApproval) }]
                  }
                  onChange={() => setAction(item, Actions.wait)}
                  value={item.action?.value || Actions.wait}
                />
              </div>
              <div title={bundle.getMessage(Actions.editDates)}>
                <RadioGroup
                  items={
                    [{ value: Actions.editDates, label: editDatesLabel, disabled: item.status != ApprovalStatus.PendingApproval }]
                  }
                  onChange={() => setAction(item, Actions.editDates)}
                  value={item.action?.value || null}
                />
              </div>
            </SpaceBetween>,
          sortingField: 'updated',
          width: actionColumnWidth
        },
      ]);
  }
  
  const listAllToggleHandler = async (detail: ToggleProps.ChangeDetail) => {
    setQueryAllPendingRequestsAdmin(false);
    setQueryAllRequests(detail.checked);
    await props.setStatusFilterCallback(detail.checked ? [ApprovalStatus.Activated, ApprovalStatus.Approved, ApprovalStatus.Deactivated, ApprovalStatus.Denied, ApprovalStatus.PendingApproval, ApprovalStatus.PendingAssessment, ApprovalStatus.PendingPOCApproval, ApprovalStatus.Submitted] : [ApprovalStatus.PendingApproval, ApprovalStatus.PendingPOCApproval], queryAllPendingRequestsAdmin);
  };
  
  const listAllPendingRequestsAdminToggleHandler = async (detail: ToggleProps.ChangeDetail) => {
    setQueryAllRequests(false);
    setQueryAllPendingRequestsAdmin(detail.checked);
    await props.setStatusFilterCallback([ApprovalStatus.PendingApproval, ApprovalStatus.PendingPOCApproval], !queryAllPendingRequestsAdmin);
  };

  const setPreferences = async (details: any) => {
    setPageSize(details.pageSize);
    props.setPageSizePrefCallback(details.pageSize);
  };

  useEffect(() => {
    setApprovedLabel(actionColumnWidth <= actionColumnVerboseMinWidth || isBundleLoading ? <Icon name="thumbs-up" alt='approve' /> : bundle.getMessage(Actions.approve));
    setDeniedLabel(actionColumnWidth <= actionColumnVerboseMinWidth || isBundleLoading ? <Icon name="thumbs-down" alt='deny' /> : bundle.getMessage(Actions.deny));
    setWaitLabel(actionColumnWidth <= actionColumnVerboseMinWidth || isBundleLoading ? <Icon name="status-pending" alt='wait' /> : bundle.getMessage(Actions.wait));
    setEditDatesLabel(actionColumnWidth <= actionColumnVerboseMinWidth || isBundleLoading ? <Icon name="edit" alt='edit_dates' /> : bundle.getMessage(Actions.editDates));
  }, [actionColumnWidth, isBundleLoading]);

  useEffect(() => {
    visitorAccessLevelApprovalsQuery.refetch();
  }, [selectedItem])
  
  useEffect(() => {
    const currentItems = allItems;
    // add chosen action back
    for (let i = 0; i < props.manageRequestsAccessLevels.length; i++) {
      for (let j = 0; j < currentItems.length; j++) {
        if (currentItems[j].action?.value && props.manageRequestsAccessLevels[i].id == currentItems[j].id) {
          props.manageRequestsAccessLevels[i].action =
            {
              value: currentItems[j].action!.value,
              label: !isBundleLoading ? bundle.getMessage(currentItems[j].action!.value) : currentItems[j].action!.value
            };
        }
      }
    }
    setSelectedItem(null);
    setAllItems(props.manageRequestsAccessLevels);
  }, [props.manageRequestsAccessLevels]);

  useEffect(() => {
    setQueryAllPendingRequestsAdmin(props.manageVisitorAccessLevelQueryAdmin);
  }, [props.manageVisitorAccessLevelQueryAdmin]);

  useEffect(() => {
    console.log(`ManageAccessRequestsTablePanel() useEffect()[selectedItem] isBundleLoading is ${isBundleLoading} selectedItem is     ${JSON.stringify(selectedItem)}`);
    if (isBundleLoading || selectedItem == null) return;
    console.log(`ManageAccessRequestsTablePanel() useEffect()[selectedItem] calling setSplitPanelCallack`);
    props.setSplitPanelCallback(
      <SplitPanel
        header={`${bundle.getMessage('access-request-details')} for ${selectedItem.person_id} ${selectedItem.access_level_name}`}
        hidePreferencesButton
        i18nStrings={{
          closeButtonAriaLabel: 'Close panel',
          openButtonAriaLabel: 'Open panel',
          preferencesConfirm: 'Confirm',
          preferencesCancel: 'Cancel',
          preferencesPositionBottom: 'Bottom',
          preferencesPositionDescription: 'Choose the default split panel position for the service.',
          preferencesPositionLabel: 'Split panel position',
          preferencesPositionSide: 'Side',
          preferencesTitle: 'Split panel preferences',
          resizeHandleAriaLabel: 'Resize split panel',
        }}
      >
    <Container
      key={'ManageVisitorAssetDetails'}
      children={
        <>
          <Details/>
          <br/>
          {selectedItem
          && visitorAccessLevelApprovalsQuery.data
          &&
          <ManageAccessRequestDetailsTablePanel
            isLoading={visitorAccessLevelApprovalsQuery.isLoading || visitorAccessLevelApprovalsQuery.isFetching}
            refreshCallback={refresh}
            visitorAccessLevelApprovals={visitorAccessLevelApprovalsQuery.data}
          />}
        </>
      }
    />
    </SplitPanel>);
    props.setSplitPanelOpenCallback(true);
  }, [visitorAccessLevelApprovalsQuery.data]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {
      showSubmissionSuccessMessage
      &&
      <Alert
        dismissible={true}
        onDismiss={() => setShowSubmissionSuccessMessage(false)}
        statusIconAriaLabel='success'
        type='success'
        header={bundle.getMessage('submission-success-message')}
      />
    }
    {
      showSubmissionFailureMessage
      &&
      <Alert
        dismissible={true}
        onDismiss={() => setShowSubmissionFailureMessage(false)}
        statusIconAriaLabel='error'
        type='error'
        header={bundle.getMessage('submission-failure-message')}
      >
        {submissionFailureErrorText}
      </Alert> 
    }
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions()}
        filter={
          <Grid gridDefinition={[{colspan: 4},{colspan: 2}]}>
            <TextFilter
              {...filterProps}
              filteringAriaLabel={bundle.getMessage('filter-manage-access-requests')}
              filteringPlaceholder={bundle.getMessage('find-manage-access-requests')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            />
            <Toggle
              onChange={({ detail }) => listAllToggleHandler(detail)}
              checked={queryAllRequests}
            >
              {bundle.getMessage('list-all-requests')}
            </Toggle>
            {
              props.admin &&
              <Toggle
                onChange={({ detail }) => listAllPendingRequestsAdminToggleHandler(detail)}
                checked={queryAllPendingRequestsAdmin}
              >
                {bundle.getMessage('list-all-pending-requests')}
              </Toggle>
            }
          </Grid>
        }
        footer={
          <Header
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  disabled={!allItems.some(i => i.action?.value == Actions.approve || i.action?.value == Actions.deny || i.action?.value == Actions.editDates || i.action?.value == Actions.pocApprove)}
                  onClick={resetActions}
                  variant='normal'
                >
                  {bundle.getMessage('reset-actions')}
                </Button>
                <Button
                  disabled={!allItems.some(i => i.action?.value == Actions.approve || i.action?.value == Actions.deny || i.action?.value == Actions.editDates || i.action?.value == Actions.pocApprove)}
                  onClick={() => setShowSubmitActions(true)}
                  variant='primary'
                >
                  {bundle.getMessage('submit-actions')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <CsvDownloadButton
                  data={allPageItems}
                  delimiter={','}
                  disabled={visitorAccessLevelApprovalsQuery.isFetching || items.length < 1}
                  filename={`ManageAccessRequests-${new Date().toISOString()}`}
                />
                <Button
                  loading={refreshing}
                  key="refreshBtn"
                  onClick={refreshBtnClickHandler}
                  iconName='refresh'
                >
                  {bundle.getMessage('refresh')}
                </Button>
              </SpaceBetween>
            }
          >
            {bundle.getMessage('manage-access-requests')}
          </Header>
        }
        items={items}
        loading={manageVisitorAccessLevelsQueryState?.fetchStatus == 'fetching'}
        empty={manageVisitorAccessLevelsQueryState?.status == 'error'
          ?
            <Alert type='error'>
              `${bundle.getMessage('query-failure')} (${typeof manageVisitorAccessLevelsQueryState?.error === 'object'
                 ? JSON.stringify(manageVisitorAccessLevelsQueryState.error)
                 : manageVisitorAccessLevelsQueryState?.error})`
            </Alert>
          : bundle.getMessage('no-access-requests-found')
        }
        loadingText={bundle.getMessage('loading-manage-access-requests')}
        onColumnWidthsChange = {({ detail }) => {
          //the last column width value does not get updated in this object when resizing
          //so we get the value of the total table width and subtract it from the window width to get the last column real width
          let total = 0;
          for (let i = 0; i < detail.widths.length; i++) {
            total += detail.widths[i];
          }
          setActionColumnWidth(window.innerWidth - total);
        }
        }
        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0]) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            title={bundle.getMessage('preferences')}
            confirmLabel={bundle.getMessage('confirm')}
            cancelLabel={bundle.getMessage('cancel')}
            preferences={{
              pageSize: pageSize,
            }}
            pageSizePreference={{
              title: bundle.getMessage('select-page-size'),
              options: [
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 15, label: '15' },
                { value: 25, label: '25' },
                { value: 50, label: '50' },
                { value: 100, label: '100' }
              ],
            }}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItem ? [selectedItem] : []}
        selectionType='single'
        stickyHeader={true}
        trackBy='id'
        visibleColumns={columnDefinitions().map(cd => {
          if (cd.id && cd.id !== 'requestedBy') return cd.id;
          return '';
        })}
      />
    </div>
    {
      showSubmitActions
      &&
      <SubmitActions
        accessLevelRequests={allItems.filter(i => (i.action?.value == Actions.approve || i.action?.value == Actions.deny || i.action?.value == Actions.editDates || i.action?.value == Actions.pocApprove)) || []}
        cancelSubmitActionsCallback={() => setShowSubmitActions(false)}
        submitActionsCallback={submitActions}
        submissionProcessing={visitorAccessLevelSubmitActionsMutation.isLoading}
        username={props.username}
      />
    }
    </>
  );
}
