import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query'
import { Cards, Container, FormField, Grid, Icon, Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { AmazonOrangeColor, ApprovalStatus, VisitorRequestStatus } from 'src/constants/Constants';
import {
  queryVisitorAccessLevelsForApprover,
  queryVisitorAssetsForApprover,
  queryVisitorRequestsForSite
} from 'src/components/utils';
import { DateFunctions } from 'src/components/utils';
import { Link } from 'react-router-dom';
import { debug } from 'src/utils/commonUtils';
import PopoverText from 'src/components/common/PopoverText';

interface IManagementHomeProps {
  setPageCallback: Function;
  siteId: string | undefined;
  username: string;
}

type TCardItem = {
  alt: string,
  approvedCurrentMonth?: JSX.Element | number | string,
  approvedPriorMonth?: JSX.Element | number | string,
  checkedInCurrentMonth?: JSX.Element | number | string,
  checkedInPriorMonth?: JSX.Element | number | string,
  scheduledCurrentMonth?: JSX.Element | number | string,
  code: string,
  deniedCurrentMonth?: JSX.Element | number | string,
  deniedPriorMonth?: JSX.Element | number | string,
  info?: JSX.Element | number | string,
  link: string,
  pending?: JSX.Element | number | string,
  tab: string,
}

export default function ManagementHome(props: IManagementHomeProps) {
  debug(`ManagementHome() props is ${JSON.stringify(props)}`);

  const [firstDayOfCurrentMonth, setFirstDayOfCurrenMonth] = useState<Date>(DateFunctions.getFirstDayOfCurrentMonth());
  const [firstDayOfPriorMonth, setFirstDayOfPriorMonth] = useState<Date>(DateFunctions.getFirstDayOfPriorMonth());
  const [lastDayOfCurrentMonth, setLastDayOfCurrenMonth] = useState<Date>(DateFunctions.getLastDayOfCurrentMonth());
  const [lastDayOfPriorMonth, setLastDayOfPriorMonth] = useState<Date>(DateFunctions.getLastDayOfPriorMonth());

  const [bundle, isBundleLoading] = useBundle('components.Management.Home.index');

  const total = <T extends {updated: string, approval_status?: string | null, status?: string | null} >(
    values: T[],
    approvalStatus: ApprovalStatus | VisitorRequestStatus,
    date: Date): number =>
  {
    let total = 0;
    total = values.reduce((total, value) => {
      const yearMonth = `${date.getFullYear()}-${date.getMonth()+1}`;
      const updatedDate = new Date(value.updated);
      const updatedYearMonth = `${updatedDate.getFullYear()}-${updatedDate.getMonth()+1}`;
      let status = value.status || value.approval_status;
      status == approvalStatus && updatedYearMonth == yearMonth ? total++ : total; return total
      }, 0);
    return total;
  };

  const managePendingVisitorAccessLevelsQuery = useQuery({
    queryKey: ['managePendingVisitorAccessLevels'],
    queryFn: () => queryVisitorAccessLevelsForApprover(props.username, [ApprovalStatus.PendingApproval, ApprovalStatus.PendingPOCApproval]),
    retry: 3,
  });

  const manageCurrentMonthVisitorAccessLevelsQuery = useQuery({
    queryKey: ['manageCurrentMonthVisitorAccessLevels'],
    queryFn: () => queryVisitorAccessLevelsForApprover(props.username, [ApprovalStatus.PendingApproval, ApprovalStatus.PendingPOCApproval], firstDayOfCurrentMonth.toISOString(), lastDayOfCurrentMonth.toISOString()),
    retry: 3,
  });

  const managePriorMonthVisitorAccessLevelsQuery = useQuery({
    queryKey: ['managePriorMonthVisitorAccessLevels'],
    queryFn: () => queryVisitorAccessLevelsForApprover(props.username, [ApprovalStatus.PendingApproval, ApprovalStatus.PendingPOCApproval], firstDayOfPriorMonth.toISOString(), lastDayOfPriorMonth.toISOString()),
    retry: 3,
  });

  const manageCurrentMonthVisitorRequestsQuery = useQuery({
    enabled: props.siteId != undefined,
    queryKey: ['manageCurrentMonthVisitorRequests'],
    queryFn: () => queryVisitorRequestsForSite(props.siteId!, undefined, firstDayOfCurrentMonth.toISOString(), lastDayOfCurrentMonth.toISOString()),
    retry: 3,
  });

  const managePendingAssetsQuery = useQuery({
    queryKey: ['managePendingAssets'],
    queryFn: () => queryVisitorAssetsForApprover(props.username, ApprovalStatus.PendingApproval),
    retry: 3,
  });

  const manageCurrentMonthAssetsQuery = useQuery({
    queryKey: ['manageCurrentMonthAssets'],
    queryFn: () => queryVisitorAssetsForApprover(props.username, undefined, firstDayOfCurrentMonth.toISOString(), lastDayOfCurrentMonth.toISOString()),
    retry: 3,
  });

  const managePriorMonthAssetsQuery = useQuery({
    queryKey: ['managePriorMonthAssets'],
    queryFn: () => queryVisitorAssetsForApprover(props.username, undefined, firstDayOfPriorMonth.toISOString(), lastDayOfPriorMonth.toISOString()),
    retry: 3,
  });

  const deriveCardItems = (): TCardItem[] => {
    return(
      [
        {
          alt: 'Manage Access Requests',
          approvedCurrentMonth:
            <>
              {
                manageCurrentMonthVisitorAccessLevelsQuery.isFetching
                  ? <Spinner/>
                  : total(manageCurrentMonthVisitorAccessLevelsQuery.data || [], ApprovalStatus.Approved, DateFunctions.getFirstDayOfCurrentMonth())
              }
            </>,
          approvedPriorMonth:
            <>
              {
                managePriorMonthVisitorAccessLevelsQuery.isFetching
                  ? <Spinner/>
                  : total(managePriorMonthVisitorAccessLevelsQuery.data || [], ApprovalStatus.Approved, DateFunctions.getFirstDayOfPriorMonth())
              }
            </>,
          code: 'manage-access-requests',
          deniedCurrentMonth:
            <>
              {
                manageCurrentMonthVisitorAccessLevelsQuery.isFetching
                  ? <Spinner/>
                  : total(manageCurrentMonthVisitorAccessLevelsQuery.data || [], ApprovalStatus.Denied, DateFunctions.getFirstDayOfCurrentMonth())
              }
            </>,
          deniedPriorMonth:
            <>
              {
                managePriorMonthVisitorAccessLevelsQuery.isFetching
                  ? <Spinner/>
                  : total(managePriorMonthVisitorAccessLevelsQuery.data || [], ApprovalStatus.Denied, DateFunctions.getFirstDayOfPriorMonth())
              }
            </>,
          info:
            <>
              &nbsp;
              <PopoverText title={bundle.getMessage("accessRequestsHoverText")} />
            </>, 
          link: '/management/manageaccessrequests',
          pending:
            <>
              {
                managePendingVisitorAccessLevelsQuery.isFetching
                  ? <Spinner/>
                  : managePendingVisitorAccessLevelsQuery?.data?.length || 0
              }
            </>,
          tab: 'Manage Access Requests',
        },
        {
          alt: 'Manage Visitors',
          checkedInCurrentMonth:
            <>
              {
                !props.siteId
                ? <span style={{"color":"red"}}>{bundle.getMessage('select-a-site')}</span>
                : manageCurrentMonthVisitorRequestsQuery.isFetching
                  ? <Spinner/>
                  : total(manageCurrentMonthVisitorRequestsQuery.data || [], VisitorRequestStatus.CheckedIn, DateFunctions.getFirstDayOfCurrentMonth())
              }
            </>,
          checkedInPriorMonth:
            <>
              {
                !props.siteId
                ? <span style={{"color":"red"}}>{bundle.getMessage('select-a-site')}</span>
                : manageCurrentMonthVisitorRequestsQuery.isFetching
                  ? <Spinner/>
                  : total(manageCurrentMonthVisitorRequestsQuery.data || [], VisitorRequestStatus.CheckedIn, DateFunctions.getFirstDayOfPriorMonth())
                  + total(manageCurrentMonthVisitorRequestsQuery.data || [], VisitorRequestStatus.CheckedOut, DateFunctions.getFirstDayOfPriorMonth())
              }
            </>,
          code: 'manage-visitors',
          info:
          <>
            &nbsp;
            <PopoverText title={bundle.getMessage("visitorRequestsHoverText")} />
          </>, 
          scheduledCurrentMonth:
            <>
              {
                !props.siteId
                ? <span style={{"color":"red"}}>{bundle.getMessage('select-a-site')}</span>
                : manageCurrentMonthVisitorRequestsQuery.isFetching
                  ? <Spinner/>
                  : total(manageCurrentMonthVisitorRequestsQuery.data || [], VisitorRequestStatus.ScheduledVisit, DateFunctions.getFirstDayOfCurrentMonth())
              }
            </>,
          link: '/management/managevisitors',
          pending:
            <>
              {
                !props.siteId
                ? <span style={{"color":"red"}}>{bundle.getMessage('select-a-site')}</span>
                : manageCurrentMonthVisitorRequestsQuery.isFetching
                  ? <Spinner/>
                  : total(manageCurrentMonthVisitorRequestsQuery.data || [], VisitorRequestStatus.PendingApproval, DateFunctions.getFirstDayOfCurrentMonth())
              }
            </>,
          tab: 'Manage Visitors',
        },
        {
          alt: 'Manage Assets',
          approvedCurrentMonth:
            <>
              {
                manageCurrentMonthAssetsQuery.isFetching
                  ? <Spinner/>
                  : total(manageCurrentMonthAssetsQuery.data || [], ApprovalStatus.Approved, DateFunctions.getFirstDayOfCurrentMonth())
              }
            </>,
          approvedPriorMonth:
            <>
              {
                managePriorMonthAssetsQuery.isFetching
                  ? <Spinner/>
                  : total(managePriorMonthAssetsQuery.data || [], ApprovalStatus.Approved, DateFunctions.getFirstDayOfPriorMonth())
              }
            </>,
          code: 'manage-assets',
          deniedCurrentMonth:
            <>
              {
                manageCurrentMonthAssetsQuery.isFetching
                  ? <Spinner/>
                  : total(manageCurrentMonthAssetsQuery.data || [], ApprovalStatus.Denied, DateFunctions.getFirstDayOfCurrentMonth())
              }
            </>,
          deniedPriorMonth:
            <>
              {
                managePriorMonthAssetsQuery.isFetching
                  ? <Spinner/>
                  : total(managePriorMonthAssetsQuery.data || [], ApprovalStatus.Denied, DateFunctions.getFirstDayOfPriorMonth())
              }
            </>,
          info:
            <>
              &nbsp;
              <PopoverText title={bundle.getMessage("assetsHoverText")} />
            </>,
          link: '/management/manageassets',
          pending:
            <>
              {
                managePendingAssetsQuery.isFetching
                  ? <Spinner/>
                  : managePendingAssetsQuery?.data?.length || 0
              }
            </>,
          tab: 'Manage Assets',
        },
     ]);
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <Container>
      <Cards
        cardDefinition={{
          header: card =>
            <div>
              <Link
                onClick={(e) => {
                  props.setPageCallback(card.link, card.tab);
                }}
                style={{ color: AmazonOrangeColor }}
                to=''
              >
                <span>{bundle.getMessage(card.code)}</span>
              </Link>
              <span>{card.info}</span>
            </div>,
          sections: [
            {
              content: e => e.pending,
              header: bundle.getMessage('pending'),
              id: 'pending',
            },
            {
              content: e =>
                <>
                {e.approvedCurrentMonth &&
                <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
                  <FormField label={bundle.getMessage('approved')}>
                    {e.approvedCurrentMonth}
                  </FormField>
                  <FormField label={bundle.getMessage('denied')}>
                    {e.deniedCurrentMonth}
                  </FormField>
                </Grid>
                }
                {e.checkedInCurrentMonth &&
                <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
                  <FormField label={bundle.getMessage('checked-in')}>
                    {e.checkedInCurrentMonth}
                  </FormField>
                  <FormField label={bundle.getMessage('scheduled')}>
                    {e.scheduledCurrentMonth}
                  </FormField>
                </Grid>
                }
                </>,
              header: bundle.getMessage('current-month'),
              id: 'currentMonth',
            },
            {
              content: e =>
                <>
                {e.approvedPriorMonth &&
                <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
                  <FormField label={bundle.getMessage('approved')}>
                    {e.approvedPriorMonth}
                  </FormField>
                  <FormField label={bundle.getMessage('denied')}>
                    {e.deniedPriorMonth}
                  </FormField>
                </Grid>
                }
                {e.checkedInPriorMonth &&
                <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
                  <FormField label={bundle.getMessage('checked-in')}>
                    {e.checkedInPriorMonth}
                  </FormField>
                </Grid>
                }
                </>,
              header: bundle.getMessage('prior-month'),
              id: 'priorMonth',
            },
          ]
        }}
        cardsPerRow={[
          { cards: 4 },
          { minWidth: 500, cards: 4 }
        ]}
        items={deriveCardItems()}
        loadingText='Loading resources'
        trackBy='code'
        visibleSections={
          [
            'pending',
            'currentMonth',
            'priorMonth',
          ]}
      />
    </Container>
  );
}