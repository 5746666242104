/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onVisitorRequestUpdate = /* GraphQL */ `
  subscription OnVisitorRequestUpdate($request_id: ID!, $visitor_id: ID!) {
    onVisitorRequestUpdate(request_id: $request_id, visitor_id: $visitor_id) {
      request_id
      visitor_id
      status_id
    }
  }
`;
