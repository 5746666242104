import React, { useEffect, useState } from 'react';
import * as APIt from "src/API";
import { useParams } from "react-router-dom";
import { UseQueryResult } from '@tanstack/react-query'
import { ActionFromLinkStatus, ApprovalStatus, Modes } from 'src/constants/Constants';
import ManageAccessRequestsTablePanel, { IVisitorAccessLevelWithAction } from './TablePanel';
import { Modal, Spinner } from '@amzn/awsui-components-react';
import {
  handleApproval,
  handleDenial,
  queryVisitorAccessLevelApproval,
  queryVisitorAccessLevel,
} from './utils';
import { queryLookupTypeValue } from 'src/components/utils';
import ActionFromLink from './ActionFromLink';
import { useBundle } from '@amzn/react-arb-tools';
import { debug } from '../../../utils/commonUtils';

interface IManageAccessRequestsProps {
  admin: boolean;
  manageVisitorAccessLevelsQuery: UseQueryResult<IVisitorAccessLevelWithAction[] | null, unknown>;
  manageVisitorAccessLevelQueryAdmin: boolean;
  mode: Modes;
  pageSize: number;
  setPageSizePrefCallback: Function;
  setSplitPanelCallback: Function;
  setSplitPanelOpenCallback: Function;
  setStatusFilterCallback: Function;
  username: string;
}

export default function ManageAccessRequests(props: IManageAccessRequestsProps) {
  debug(`ManageAccessRequests() props is ${JSON.stringify(props)}`);
  debug(`ManageAccessRequests() useParams() is ${JSON.stringify(useParams())}`);

  const [actionFromLink, setActionFromLink] = useState<string>(useParams().action || '');
  const [actionFromLinkStatus, setActionFromLinkStatus] = useState<ActionFromLinkStatus>(ActionFromLinkStatus.Checking);
  const [actionFromLinkErrorMessage, setActionFromLinkErrorMessage] = useState<string | null>(null);
  const [denyReasonId, setDenyReasonId] = useState<string>(useParams().denyReasonId || '');
  const [handleActionFromLinkError, setHandleActionFromLinkError] = useState<string>('');
  const [showActionFromLink, setShowActionFromLink] = useState<boolean>(false);
  const [showSubmissionDenialNotes, setShowSubmissionDenialNotes] = useState<boolean>(false);
  const [submissionDenialNotes, setSubmissionDenialNotes] = useState<string>('');
  const [visitorAccessLevel, setVisitorAccessLevel] = useState<APIt.VisitorAccessLevel | null>();
  const [visitorAccessLevelApproval, setVisitorAccessLevelApproval] = useState<APIt.VisitorAccessLevelApproval | null>();
  const [visitorAccessLevelApprovalIdFromLink, setVisitorAccessLevelApprovalIdFromLink] = useState<string>(useParams().visitorAccessLevelApprovalId || '');
  const [visitorAccessLevelWithActionFromLink, setVisitorAccessLevelWithActionFromLink] = useState<IVisitorAccessLevelWithAction>();

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.index');

  const refresh = async () => {
    await props.manageVisitorAccessLevelsQuery.refetch();
  };

  const approveFromLink = async (visitorAccessLevel: APIt.VisitorAccessLevel, visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval) => {
    debug(`ManageAccessRequests() approveFromLink() visitorAccessLevel is ${visitorAccessLevel} visitorAccessLevelApprover is ${JSON.stringify(visitorAccessLevelApproval)}`);

    const action: IVisitorAccessLevelWithAction = {
      ...visitorAccessLevel,
      created: '',
      person_id: visitorAccessLevel.person_id || '',
      updated: '',
      updated_by: props.username,
      visitor_id: visitorAccessLevel.visitor_id,
      visitor_type: visitorAccessLevel.visitor_type,
    };
    setVisitorAccessLevelWithActionFromLink(action)
    try {
      setActionFromLinkStatus(ActionFromLinkStatus.InProgress);
      await handleApproval(visitorAccessLevelApproval, action, props.username);
      setActionFromLinkStatus(ActionFromLinkStatus.Approved);
    } catch(error) {
      setActionFromLinkStatus(ActionFromLinkStatus.Error);
      if (error instanceof Error) setActionFromLinkErrorMessage(error.message);
      if (!(error instanceof Error)) setActionFromLinkErrorMessage(JSON.stringify(error));
    }
  }

  const denyFromLink = async (visitorAccessLevel: APIt.VisitorAccessLevel, visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval, notes: string = '') => {
    debug(`ManageAccessRequests() denyFromLink() visitorAccessLevel is ${JSON.stringify(visitorAccessLevel)} visitorAccessLevelApprover is ${JSON.stringify(visitorAccessLevelApproval)}`);

    const denyReasonValue = await queryLookupTypeValue(denyReasonId);
    debug(`ManageAccessRequests() denyFromLink() denyReasonValue is ${JSON.stringify(denyReasonValue)}`);
    if (!denyReasonValue) {
      setActionFromLinkStatus(ActionFromLinkStatus.Error);
      setActionFromLinkErrorMessage(`Invalid deny reason id, ${denyReasonId}`);
      return;
    }
    const action: IVisitorAccessLevelWithAction = {
      ...visitorAccessLevel,
      created: '',
      denialReason: denyReasonValue.value,
      notes: notes,
      person_id: visitorAccessLevel.person_id || '',
      updated: '',
      updated_by: props.username,
      visitor_id: visitorAccessLevel.visitor_id,
      visitor_type: visitorAccessLevel.visitor_type,
    };
    setVisitorAccessLevelWithActionFromLink(action)
    debug(`ManageAccessRequests() denyFromLink() denyReasonValue.value is ${denyReasonValue.value} submissionDenialNotes is ${submissionDenialNotes}`);
    if (denyReasonValue.value == 'Other (See Notes)' && notes == '') {
      setActionFromLinkStatus(ActionFromLinkStatus.WaitingForInput);
      setShowSubmissionDenialNotes(true);
      return;
    }
    try {
      setActionFromLinkStatus(ActionFromLinkStatus.InProgress);
      await handleDenial(visitorAccessLevelApproval, action, props.username);
      setActionFromLinkStatus(ActionFromLinkStatus.Denied);
    } catch(error) {
      if (error instanceof Error) setHandleActionFromLinkError(`${ActionFromLinkStatus.Error}: ${error.message}`);
      if (!(error instanceof Error)) setHandleActionFromLinkError(`${ActionFromLinkStatus.Error}: ${JSON.stringify(error)}`);
    }
  }

  const handleActionFromLink = async () => {
    setShowActionFromLink(true);
    setActionFromLinkStatus(ActionFromLinkStatus.Checking);
    let visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval | null = null;
    try {
      visitorAccessLevelApproval = await queryVisitorAccessLevelApproval(visitorAccessLevelApprovalIdFromLink);
      setVisitorAccessLevelApproval(visitorAccessLevelApproval);
    } catch(error) {
      console.error(error);
      setActionFromLinkStatus(ActionFromLinkStatus.Error);
      if (error instanceof Error) setHandleActionFromLinkError(`${ActionFromLinkStatus.Error}: ${error.message}`);
      if (!(error instanceof Error)) setHandleActionFromLinkError(`${ActionFromLinkStatus.Error}: ${JSON.stringify(error)}`);
      return;
    }
    if (!visitorAccessLevelApproval || visitorAccessLevelApproval.approver_id !== props.username) {
      setActionFromLinkStatus(ActionFromLinkStatus.NotFound);
      return;
    }
    debug(`ManageAccessRequests() handleActionFromLink() visitorAccessLevelApprover is ${JSON.stringify(visitorAccessLevelApproval)}`);
    if (visitorAccessLevelApproval) {
      const visitorAccessLevel = await queryVisitorAccessLevel(visitorAccessLevelApproval?.visitor_access_level_id);
      setVisitorAccessLevel(visitorAccessLevel);
      debug(`ManageAccessRequests() handleActionFromLink() visitorAccessLevel is ${JSON.stringify(visitorAccessLevel)}`);
      if (visitorAccessLevelApproval?.status !== ApprovalStatus.PendingApproval) {
        switch(visitorAccessLevel?.status) {
          case ApprovalStatus.Activated:
            setActionFromLinkStatus(ActionFromLinkStatus.AlreadyApproved);
            break;
          case ApprovalStatus.Approved:
            setActionFromLinkStatus(ActionFromLinkStatus.AlreadyApproved);
            break;
          case ApprovalStatus.Cancelled:
            setActionFromLinkStatus(ActionFromLinkStatus.AlreadyCancelled);
            break;
          case ApprovalStatus.Deactivated:
            setActionFromLinkStatus(ActionFromLinkStatus.AlreadyApproved);
            break;
          case ApprovalStatus.Denied:
            setActionFromLinkStatus(ActionFromLinkStatus.AlreadyDenied);
            break;
        }
        return;
      }
      if (visitorAccessLevel) {
        switch(actionFromLink) {
          case 'approve':
            approveFromLink(visitorAccessLevel, visitorAccessLevelApproval);
            break;
          case 'deny':
            denyFromLink(visitorAccessLevel, visitorAccessLevelApproval);
          default:
            break;
        }
      }
    }
  };

  const handleActionFromLinkOKButton = async (denialNotes: string) => {
    debug(`ManageAccessRequests() handleActionFromLinkOKButton() showSubmissionDenialNotes is ${showSubmissionDenialNotes} denialNotes is ${denialNotes} visitorAccessLevel is ${JSON.stringify(visitorAccessLevel)} visitorAccessLevelApprover is ${JSON.stringify(visitorAccessLevelApproval)}`);
    if (denialNotes && visitorAccessLevel && visitorAccessLevelApproval) {
      setActionFromLinkStatus(ActionFromLinkStatus.InProgress);
      debug(`ManageAccessRequests() handleActionFromLinkOKButton() before calling denyFromLink()`);
      setShowSubmissionDenialNotes(false);
      await denyFromLink(visitorAccessLevel, visitorAccessLevelApproval, denialNotes);
      debug(`ManageAccessRequests() handleActionFromLinkOKButton() after calling denyFromLink()`);
      setSubmissionDenialNotes('');
      return;
    }
    window.location.replace(`${window.location.origin}/management/manageaccessrequests`);
  };

  useEffect(() => {
    (async () => {
      if (visitorAccessLevelApprovalIdFromLink) await handleActionFromLink();
    })();
  }, []);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      <ManageAccessRequestsTablePanel
        admin={props.admin}
        isTableLoading={props.manageVisitorAccessLevelsQuery.isLoading}
        manageRequestsAccessLevels={props.manageVisitorAccessLevelsQuery.data || []}
        manageVisitorAccessLevelQueryAdmin={props.manageVisitorAccessLevelQueryAdmin}
        pageSize={props.pageSize}
        refreshCallback={refresh}
        setPageSizePrefCallback={props.setPageSizePrefCallback}
        setSplitPanelCallback={props.setSplitPanelCallback}
        setSplitPanelOpenCallback={props.setSplitPanelOpenCallback}
        setStatusFilterCallback={async (statuses: string[], admin: boolean) =>
          {
            await props.setStatusFilterCallback({ approvalStatuses: statuses, admin: admin });
            props.manageVisitorAccessLevelsQuery.refetch();
          }
        }
        username={props.username}
      />
      {
        visitorAccessLevelApprovalIdFromLink
        &&
        showActionFromLink
        &&
        <Modal
          header={`${bundle.getMessage(actionFromLink)}`}
          onDismiss={() => setShowActionFromLink(false)}
          size='large'
          visible={true}
        >
          <form onSubmit={e => e.preventDefault()}>
            <ActionFromLink
              error={handleActionFromLinkError}
              okCallback={handleActionFromLinkOKButton}
              errorMessage={actionFromLinkErrorMessage}
              showSubmissionDenialNotes={showSubmissionDenialNotes}
              setSubmissionDenialNotesCallback={setSubmissionDenialNotes}
              status={actionFromLinkStatus}
              visitorAccessLevelApprovalId={visitorAccessLevelApprovalIdFromLink}
              visitorAccessLevelWithAction={visitorAccessLevelWithActionFromLink}
            />
          </form>
        </Modal>
      }
    </>
  );
}