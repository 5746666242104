import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
  Header,
  Modal,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { Actions, IVisitorAccessLevelWithAction } from './TablePanel';
import SubmissionApproval from './SubmissionApproval';
import SubmissionDenial from './SubmissionDenial';
import * as APIt from '../../../API';
import { debug } from 'src/utils/commonUtils';
import { AccessRequestEditPanel } from './EditDates';

export interface ISubmitActionsProps{
  accessLevelRequests: IVisitorAccessLevelWithAction[];
  cancelSubmitActionsCallback: Function;
  submitActionsCallback: Function;
  submissionProcessing: boolean;
  username: string;
}

export default function SubmitActions(props: ISubmitActionsProps) {
  debug(`SubmitActions() props is ${JSON.stringify(props)}`);

  const [accessLevelRequests, setAccessLevelRequests] = useState<APIt.VisitorAccessLevel[]>(props.accessLevelRequests);
  const [accessLevelRequestsWithAction, setAccessLevelRequestsWithAction] = useState<IVisitorAccessLevelWithAction[]>([]);
  const [errorText, setErrorText] = useState<string>();
  const [formComplete, setFormComplete] = useState<boolean>(false);
  const [missingActiveBadge, setMissingActiveBadge] = useState<boolean | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.SubmitActions');

  const setNotesForAccessRequest = (id: string, notes: string): void => {
    debug(`SubmitActions() setNotesForAccessRequest() id is ${id} notes is ${notes}`);
    const accessLevelRequestWithAction = accessLevelRequestsWithAction.find(acl => acl.id == id);
    if (accessLevelRequestWithAction) {
      accessLevelRequestWithAction.notes = notes;
      const newAccessLevelRequestsWithAction = 
        [
          ...(accessLevelRequestsWithAction.filter(ala => ala.id != accessLevelRequestWithAction.id)),
          accessLevelRequestWithAction
        ];
      debug(`SubmitActions() setNotesForAccessRequest() newAccessLevelRequestsWithAction is ${JSON.stringify(newAccessLevelRequestsWithAction)}`);
      setAccessLevelRequests(newAccessLevelRequestsWithAction);
    }
  };

  const setDenialReasonForAccessRequest = (id: string, denialReason: string): void => {
    debug(`SubmitActions() setDenialReasonForAccessRequest() id is ${id} denialReason is ${denialReason}`);
    debug(`SubmitActions() setDenialReasonForAccessRequest() accessLevelRequestsWithAction is ${JSON.stringify(accessLevelRequestsWithAction)}`);
    const accessLevelRequestWithAction = accessLevelRequestsWithAction.find(acl => acl.id == id);
    if (accessLevelRequestWithAction) {
      accessLevelRequestWithAction.denialReason = denialReason;
      debug(`SubmitActions() setDenialReasonForAccessRequest() accessLevelRequestWithAction is ${JSON.stringify(accessLevelRequestWithAction)}`);
      const otherAccessLevelRequestsWithAction = [...(accessLevelRequestsWithAction.filter(ala => ala.id != accessLevelRequestWithAction.id))];
      debug(`SubmitActions() setDenialReasonForAccessRequest() otherAccessLevelRequestsWithAction is ${JSON.stringify(otherAccessLevelRequestsWithAction)}`);
      const newAccessLevelRequestsWithAction = 
        [
          ...(accessLevelRequestsWithAction.filter(ala => ala.id != accessLevelRequestWithAction.id)),
          accessLevelRequestWithAction
        ];
      debug(`SubmitActions() setDenialReasonForAccessRequest() newAccessLevelRequestsWithAction is ${JSON.stringify(newAccessLevelRequestsWithAction)}`);
      setAccessLevelRequestsWithAction(newAccessLevelRequestsWithAction);
      checkFormComplete();
    }
  };

  const setDenialNotesForAccessRequest = (id: string, notes: string): void => {
    debug(`SubmitActions() setDenialNotesForAccessRequest() id is ${id} notes is ${notes}`);
    const accessLevelRequestWithAction = accessLevelRequestsWithAction.find(acl => acl.id == id);
    if (accessLevelRequestWithAction) {
      accessLevelRequestWithAction.notes = notes;
      setAccessLevelRequests(
        [
          ...(accessLevelRequestsWithAction.filter(ala => ala.id != accessLevelRequestWithAction.id)),
          accessLevelRequestWithAction
        ]);
    }
  };

  const checkFormComplete = (): void => {
    debug(`SubmitActions() checkFormComplete() accessLevelRequestsWithAction.length is ${accessLevelRequestsWithAction.length}`);
    if (accessLevelRequestsWithAction.length == 0) {
      setFormComplete(false);
      return;
    }
    let result = true;
    for (let i = 0; i < accessLevelRequestsWithAction.length; i++) {
      debug(`SubmitActions() checkFormComplete() accessLevelRequestsWithAction[${i}] is ${JSON.stringify(accessLevelRequestsWithAction[i])}`);
      if (accessLevelRequestsWithAction[i].action?.value == Actions.deny && !accessLevelRequestsWithAction[i].denialReason
        || accessLevelRequestsWithAction[i].action?.value == Actions.editDates && accessLevelRequestsWithAction[i].invalidDates
      ) {
        result = false;
        break;
      }
    }
    setFormComplete(result);
  };

  const setEditDatesForAccessRequest = (id: string, startDate: string, endDate: string, permanentFlag: boolean, dates_updated: boolean, updatedBy: string, invalidDates: boolean): void => {
    debug(`SubmitActions() setEditDatesForAccessRequest() id is ${id} startDate is ${startDate} endDate is ${endDate} permanentFlag ${permanentFlag}`);
    const accessLevelRequestWithAction = accessLevelRequestsWithAction.find(acl => acl.id == id);
    if (accessLevelRequestWithAction) {
      accessLevelRequestWithAction.end_date = !permanentFlag ? endDate?.slice(0, 33) : null;
      accessLevelRequestWithAction.permanent_flag = permanentFlag;
      accessLevelRequestWithAction.start_date = !permanentFlag ? startDate?.slice(0, 33) : null;
      accessLevelRequestWithAction.dates_updated = dates_updated;
      accessLevelRequestWithAction.updated_by = updatedBy;
      accessLevelRequestWithAction.invalidDates = invalidDates;
      const newAccessLevelRequestsWithAction = 
        [
          ...(accessLevelRequestsWithAction.filter(ala => ala.id != accessLevelRequestWithAction.id)),
          accessLevelRequestWithAction
        ];
      debug(`SubmitActions() setEditDatesForAccessRequest() newAccessLevelRequestsWithAction is ${JSON.stringify(newAccessLevelRequestsWithAction)}`);
      setAccessLevelRequests(newAccessLevelRequestsWithAction);
      checkFormComplete();
    }
  };

  const handleMissingActiveBadgeCallback = (missingActiveBadge: boolean, id: string) => {
    const index = accessLevelRequestsWithAction.findIndex(alr => alr.id == id);
    const alrs = [...accessLevelRequestsWithAction];
    alrs[index].missingActiveBadge = missingActiveBadge;
    setAccessLevelRequestsWithAction(alrs);
  };

  useEffect(() => {
    checkFormComplete();
    return(() => {
      setMissingActiveBadge(null);
    });
  }, []);

  useEffect(() => {
    if (accessLevelRequestsWithAction.length == 0) {
      setAccessLevelRequestsWithAction(props.accessLevelRequests);
    }
  }, [props.accessLevelRequests]);

  useEffect(() => {
    checkFormComplete();
    if (accessLevelRequestsWithAction.find(alr => alr.missingActiveBadge)) {
      setMissingActiveBadge(true);
    } else if (accessLevelRequestsWithAction.length > 0
      && !accessLevelRequestsWithAction.find(alr => alr.missingActiveBadge == undefined && alr.action?.value == Actions.approve ))
    {
      setMissingActiveBadge(false);
    }
  }, [accessLevelRequestsWithAction]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      header={
        <Header>
          {bundle.getMessage('confirm-actions')}
        </Header>
      }
      onDismiss={() => props.cancelSubmitActionsCallback()}
      size='max'
      visible={true}
    >
      {missingActiveBadge == null && <Spinner/>}
      <form onSubmit={e => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid='ManageAccessRequestsSubmitActionsCancelButton'
                formAction="none"
                variant="normal"
                onClick={() =>
                  {
                    setAccessLevelRequestsWithAction([]);
                    props.cancelSubmitActionsCallback();
                  }
                }
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='ManageAccessRequestsSubmitActionsConformActionsButton'
                disabled={
                  !formComplete
                  || missingActiveBadge == null
                  || missingActiveBadge
                }
                variant="primary"
                loading={props.submissionProcessing}
                onClick={async () => 
                  {
                    props.submitActionsCallback(accessLevelRequestsWithAction);
                  }
                }
              >
                {bundle.getMessage('confirm-actions')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        >
          {
            props.accessLevelRequests.some(alr => alr.action?.value == Actions.editDates)
            &&
            <Container
              header={<>{bundle.getMessage('editDates')}</>}
            >
              {accessLevelRequestsWithAction.map((alr) => {
                if (alr.action?.value == Actions.editDates) {
                  return(
                    <AccessRequestEditPanel
                      accessRequest={alr!}
                      setDatesCallback={setEditDatesForAccessRequest}
                      username={props.username}
                    />
                  );
                };
              })}
            </Container>
          }
          {
            props.accessLevelRequests.some(alr => alr.action?.value == Actions.approve)
            &&
            <Container
              header={<>{bundle.getMessage('approve')}</>}
            >
              {accessLevelRequestsWithAction.map((alr) => {
                if (alr.action?.value == Actions.approve) {
                  return(
                    <SubmissionApproval
                      accessLevelApproval={alr}
                      setNotesCallback={setNotesForAccessRequest}
                      setMissingActiveBadgeCallback={handleMissingActiveBadgeCallback}
                    />
                  );
                };
              })}
            </Container>
          }
          {
            props.accessLevelRequests.some(alr => alr.action?.value == Actions.pocApprove)
            &&
            <Container
              header={<>{bundle.getMessage('approve')}</>}
            >
              {accessLevelRequestsWithAction.map((alr) => {
                if (alr.action?.value == Actions.pocApprove) {
                  return(
                    <SubmissionApproval
                      accessLevelApproval={alr}
                      setNotesCallback={setNotesForAccessRequest}
                      setMissingActiveBadgeCallback={handleMissingActiveBadgeCallback}
                    />
                  );
                };
              })}
            </Container>
          }
          {
            props.accessLevelRequests.some(alr => alr.action?.value == Actions.deny)
            &&
            <Container
              header={<>{bundle.getMessage('deny')}</>} 
            >
              <div id='ManagemeAccessRequestsSubmissionDenialDiv'>
                {accessLevelRequestsWithAction.map(alr => {
                  if (alr.action?.value == Actions.deny) {
                    return(
                      <SubmissionDenial
                        accessLevelDenial={alr}
                        setDenialReasonCallback={setDenialReasonForAccessRequest}
                        setDenialNotesCallback={setDenialNotesForAccessRequest}
                      />
                    );
                  };
                })}
              </div>
            </Container>
          }
        </Form>
      </form>
    </Modal>);
}